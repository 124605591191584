import { LocationQuery, NavigationGuard, NavigationGuardNext, RouteMeta } from 'vue-router';

import { MessageService } from '@/modules/shared';
import { RouteName } from '@/modules/shared/types/routes';
import { JwtService } from '@/services/jwt.service';
import { AuthModule } from '@/store/modules/auth.module';
import { UserConfigModule } from '@/store/modules/user-config.module';

function logOut(next: NavigationGuardNext, query: LocationQuery): void {
  AuthModule.logOut();
  next({ name: RouteName.login, query });
}

/**
 * We first check if the route is 'login' which is open regardless if the user is authenticated
 * Then we check if the token is valid if not redirect to login
 * If we have a valid token we verify if the user is authorized to access the route
 * If not we stay on the current page and show a 'not allowed' message
 */
export const globalGuard: NavigationGuard = async (to, from, next) => {
  const redirect = window.location.pathname + window.location.search;

  const query: LocationQuery = {};
  if (redirect !== '/' && !redirect.includes('external/login')) {
    query.redirect = redirect;
  }

  /** When we have a token and user tries to go to login we redirect to Control, since user is already logged in */
  try {
    if (AuthModule.token) {
      const isTokenExpired = JwtService.isTokenExpired(AuthModule.token);

      if (isTokenExpired) {
        logOut(next, query);
        return;
      } else if (to.name === RouteName.login) {
        next({ name: RouteName.control });
        return;
      }
    }
  } catch (err) {
    logOut(next, query);
    return;
  }

  try {
    const routeMeta: RouteMeta = to.meta;

    /** Grab the customer settings if not fetched already */
    if (AuthModule.token && !AuthModule.hasFetchedSettings) {
      await AuthModule.getSettings();
    }

    /** Check the isAllowed function and see if it doesn't pass (returns false) */
    if (routeMeta && routeMeta.isAllowed && !routeMeta.isAllowed()) {
      next({
        name: RouteName.dashboard,
      });
      MessageService.noPermission();
      return;
    }

    /** Check authorities */
    if (
      routeMeta?.authorities &&
      UserConfigModule.user?.authorities &&
      !routeMeta.authorities.every((authority) => typeof authority === 'string' && UserConfigModule.user?.authorities.includes(authority))
    ) {
      next(false);
      MessageService.noPermission();
      return;
    }

    /** If forceChangePassword is true redirect user to userChangePassword view unless user tries to log out */
    if (
      UserConfigModule.user &&
      UserConfigModule.user.forceChangePassword &&
      to.name !== RouteName.userChangePassword &&
      to.name !== RouteName.login
    ) {
      next({
        name: RouteName.userChangePassword,
      });
      return;
    }

    /** If above checks pass, continue */
    next();
  } catch (ex) {
    console.warn(ex);
    logOut(next, query);
    return;
  }
};
