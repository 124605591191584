import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const CUSTOMER_CONFIG_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/customer-config',
    name: RouteName.customerConfig,
    meta: {
      authorities: [Authorities.CustomerConfigRead],
    },
    component: () => import('@/modules/customer-config/views/CustomerConfig.vue'),
  },
];
