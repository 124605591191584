import { NavigationGuard } from 'vue-router';

import { Feature } from '@/modules/features/api/features/features.contracts';
import { FeaturesModule } from '@/modules/features/store/modules/features.module';

export const featureGuard: NavigationGuard = async (to, from, next) => {
  if (to.meta && to.meta.features) {
    const requiredFeatures: Feature[] = to.meta.features;
    try {
      const features = Object.keys(FeaturesModule.features)?.length ? FeaturesModule.features : await FeaturesModule.getFeatures();
      const hasAccess: boolean = requiredFeatures.every((requiredFeature) => features[requiredFeature] === true);
      hasAccess ? next() : next(false);
    } catch (error) {
      next(false);
    }
  } else {
    next();
  }
};
