import {
  CellClassParams,
  ColDef,
  ICellRendererParams,
  NewValueParams,
  RowNode,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-enterprise';
import { first, isEmpty, isFinite, isNil } from 'lodash-es';

import { Constants } from '@/constants';
import { translatePerformanceBandPickupHeader, translatePerformanceBandPickupHeaderTooltip } from '@/models/columns/definitions/pickup';
import {
  ColumnId,
  generateCabinLevelColumnId,
  generateCabinLevelCompetitorColumnId,
  generateCabinLevelMinCompetitorColumnId,
  generateCabinPerfomanceBandPickupColumnId,
} from '@/models/enums/grid';
import { getCabinFare, getFareForCabin } from '@/models/FareFinder';
import { FlightDynamicPropertiesAllowed, FlightViewLegCabinInventoryTactic } from '@/models/FlightModel';
import { OptimisationTactic } from '@/models/OptimisationTactic';
import { ClassStructure } from '@/modules/api/application/application-contracts';
import { FareSource } from '@/modules/api/customer-settings/customer-settings-contracts';
import { CompetitorFareGridModel } from '@/modules/api/flight/competitive-fares/competitive-fares-contracts';
import { FlightLineCabin, FlightLineCabinClass, FlightLineModel, PerformanceBandPickupModel } from '@/modules/api/flight/flight-contracts';
import { InventoryGridModel } from '@/modules/api/flight/inventory-grid-model';
import { RivalFareGridModel } from '@/modules/api/flight/rival-fares/rival-fares-contracts';
import { OptimisationProfileLevelModel } from '@/modules/api/optimisation-profiles/optimisation-profiles-contracts';
import { AddOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-optimization-profile-level-action';
import { AddShadowOptimizationProfileLevelAction } from '@/modules/flight-actions/actions/cabin-actions/add-shadow-optimization-profile-level-action';
import { RemovePromotionAction } from '@/modules/flight-actions/actions/cabin-actions/remove-promotion-action';
import { SetPricingAdjustmentAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-adjustment-action';
import { SetPricingIncrementAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-increment-action';
import { SetPricingTacticAction } from '@/modules/flight-actions/actions/cabin-actions/set-pricing-tactic-action';
import { SetPromotionAction } from '@/modules/flight-actions/actions/cabin-actions/set-promotion-action';
import { UpdateAutopilotAction } from '@/modules/flight-actions/actions/cabin-actions/update-autopilot-action';
import { FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { logger } from '@/modules/monitoring';
import { formatNumber } from '@/modules/shared';
import { CabinService } from '@/modules/shared/services/cabin.service';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { LafLoadFactorColoring } from '@/modules/user-settings/api/user/user.contracts';
import { generateLafColorScheme } from '@/modules/user-settings/utils/colors.utils';
import { i18n } from '@/plugins/i18n';
import { CalculationService } from '@/services/calculation.service';
import { DateTimeService } from '@/services/date-time.service';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';
import { FlightModule } from '@/store/modules/flight.module';

import { NumberColumnFilterSettings, SetColumnFilterSettings, TextColumnFilterSettings, getLoadFactorCellRenderParams } from './base';

const { t } = i18n.global;

export const generateCabinOptimizationTacticsColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.OptimizationTactic),
  headerName: t('active_optimisation_tactic_short'),
  field: 'optimisationTactics',
  minWidth: 80,
  width: 80,
  sortable: true,
  headerClass: 'ag-left-aligned-header',
  cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-opt-tactic-key-cell-${data.ondId}`,
  headerTooltip: `${t('active_optimisation_tactic', {
    cabin: cabinCode,
  })}`,
  valueGetter: (params: ValueGetterParams) => getOptimizationTactic(params.data, cabinCode, false),
  comparator: (valueA: string, valueB: string) => compareOptimizationTactics(valueA, valueB),
});

export const generateCabinShadowOptimizationTacticsColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ShadowTacticsOptimizationTactic),
  headerName: t('shadow_optimisation_tactic_short'),
  field: 'shadowOptimisationTactics',
  minWidth: 80,
  width: 80,
  sortable: true,
  headerClass: 'ag-left-aligned-header',
  cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-shadow-opt-tactic-key-cell-${data.ondId}`,
  headerTooltip: `${t('shadow_optimisation_tactic', {
    cabin: cabinCode,
  })}`,
  valueGetter: (params: ValueGetterParams) => getOptimizationTactic(params.data, cabinCode, true),
  comparator: (valueA: string, valueB: string) => compareOptimizationTactics(valueA, valueB),
});

function getOptimizationTactic(flightLine: FlightDynamicPropertiesAllowed, cabinCode: string, shadow: boolean): string | undefined {
  const matchingCabin = FlightService.getMatchedCabin(flightLine, cabinCode);
  if (!matchingCabin) {
    return;
  }
  const tactic = shadow ? matchingCabin.shadowOptimisationTactics : matchingCabin.optimisationTactics;
  const tacticLevel: OptimisationProfileLevelModel | undefined = shadow
    ? matchingCabin.shadowOptimisationProfileLevel
    : matchingCabin.optimisationProfileLevel;

  if (tactic !== OptimisationTactic.Manual && tacticLevel) {
    return `${tacticLevel.name} / ${tacticLevel.level}`;
  }
  return tactic || undefined;
}

function compareOptimizationTactics(valueA: string, valueB: string) {
  const isFirstRowManual = valueA === OptimisationTactic.Manual;
  const isSecondRowManual = valueB === OptimisationTactic.Manual;
  if (isFirstRowManual && isSecondRowManual) {
    // Don't sort so multi sorting doesn't get overridden.
    return 0;
  }
  // 'manual' is sorted separately from the other optimization tactics'
  if (isFirstRowManual) {
    return 1;
  }
  if (isSecondRowManual) {
    return -1;
  }
  return StringOrNumberComparator(valueA, valueB, undefined, undefined, false);
}

export const generateCabinLoadFactorColumn = (cabinCode: string, lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinLoadFactor),
  headerName: t('authorized_capacity_load_factor_short'),
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  cellRenderer: 'GridLoadFactorRenderer',
  minWidth: 40,
  width: 40,
  sortable: true,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return Number(FormatService.roundNumber(matchingCabin.lidLoadFactor, 1));
    }
  },
  cellRendererParams: (params: ICellRendererParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return getLoadFactorCellRenderParams(matchingCabin.lidLoadFactor, lafLoadFactorColoring, 'cabin');
    }
  },
  headerTooltip: t('lid_load_factor'),
});

export const generateCabinCapacityLoadFactorColumn = (cabinCode: string, lafLoadFactorColoring: LafLoadFactorColoring): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinCapacityLoadFactor),
  headerName: t('saleable_capacity_load_factor_short'),
  type: 'numericColumn',
  cellClass: 'marginless-cell',
  cellRenderer: 'GridLoadFactorRenderer',
  minWidth: 40,
  width: 40,
  sortable: true,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return Number(FormatService.roundNumber(matchingCabin.capacityLoadFactor, 1));
    }
  },
  cellRendererParams: (params: ICellRendererParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return getLoadFactorCellRenderParams(matchingCabin.capacityLoadFactor, lafLoadFactorColoring, 'cabin');
    }
  },
  headerTooltip: t('capacity_load_factor'),
});

export enum lafKeyType {
  LowestAvailableFareClass = 'lowestAvailableFareClass',
  RecommendedLowestAvailableFareClass = 'recommendedLowestAvailableFareClass',
  ShadowRecommendedLowestAvailableFareClass = 'shadowRecommendedLowestAvailableFareClass',
}

export enum seatAvailabilityKeyType {
  SeatAvailability = 'seatAvailability',
  RecommendedSeatAvailability = 'recommendedSeatAvailability',
  ShadowRecommendedSeatAvailability = 'shadowRecommendedSeatAvailability',
}

export const generateCabinLowestAvailableFareColumn = (
  cabinCode: string,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.LowestAvailableFareClass),
  headerName: t('lowest_available_fare_short'),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-laf-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridLafRenderer',
  minWidth: 35,
  width: 35,
  sortable: true,
  comparator: (valueA: string, valueB: string, nodeA: RowNode | undefined, nodeB: RowNode | undefined, isInverted) =>
    compareLaf(cabinClasses, cabinCode, valueA, valueB, nodeA, nodeB, isInverted),
  headerTooltip: `${t('lowest_available_fare', {
    cabin: cabinCode,
  })}`,
  cellRendererParams: (params: ValueFormatterParams) =>
    getLafCellRendererParams(
      params,
      cabinCode,
      lafKeyType.LowestAvailableFareClass,
      seatAvailabilityKeyType.SeatAvailability,
      cabinClasses,
      lafLoadFactorColoring,
    ),
  valueGetter: (params: ValueGetterParams): any => getLafValueGetter(params, cabinCode, lafKeyType.LowestAvailableFareClass),
});

export const generateCabinRecommendedLowestAvailableFareColumn = (
  cabinCode: string,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.RecommendedLowestAvailableFareClass),
  headerName: t('active_recommended_lowest_available_fare_short'),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-rlaf-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridLafRenderer',
  minWidth: 35,
  width: 35,
  sortable: true,
  comparator: (valueA: string, valueB: string, nodeA: RowNode | undefined, nodeB: RowNode | undefined, isInverted) =>
    compareLaf(cabinClasses, cabinCode, valueA, valueB, nodeA, nodeB, isInverted),
  headerTooltip: `${t('active_recommended_lowest_available_fare', {
    cabin: cabinCode,
  })}`,
  cellRendererParams: (params: ValueFormatterParams) =>
    getLafCellRendererParams(
      params,
      cabinCode,
      lafKeyType.RecommendedLowestAvailableFareClass,
      seatAvailabilityKeyType.RecommendedSeatAvailability,
      cabinClasses,
      lafLoadFactorColoring,
    ),
  valueGetter: (params: ValueGetterParams): any => getLafValueGetter(params, cabinCode, lafKeyType.RecommendedLowestAvailableFareClass),
});

export const generateCabinShadowRecommendedLowestAvailableFareColumn = (
  cabinCode: string,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ShadowRecommendedLowestAvailableFareClass),
  headerName: t('shadow_recommended_lowest_available_fare_short'),
  type: 'rightAligned',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-shadow-rlaf-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridLafRenderer',
  minWidth: 35,
  width: 35,
  sortable: true,
  comparator: (valueA: string, valueB: string, nodeA: RowNode | undefined, nodeB: RowNode | undefined, isInverted) =>
    compareLaf(cabinClasses, cabinCode, valueA, valueB, nodeA, nodeB, isInverted),
  headerTooltip: `${t('shadow_recommended_lowest_available_fare', {
    cabin: cabinCode,
  })}`,
  cellRendererParams: (params: ValueFormatterParams) =>
    getLafCellRendererParams(
      params,
      cabinCode,
      lafKeyType.ShadowRecommendedLowestAvailableFareClass,
      seatAvailabilityKeyType.ShadowRecommendedSeatAvailability,
      cabinClasses,
      lafLoadFactorColoring,
    ),
  valueGetter: (params: ValueGetterParams): any =>
    getLafValueGetter(params, cabinCode, lafKeyType.ShadowRecommendedLowestAvailableFareClass),
});

function compareLaf(
  cabinClasses: ClassStructure[],
  cabinCode: string,
  valueA: string,
  valueB: string,
  nodeA: RowNode | undefined,
  nodeB: RowNode | undefined,
  isInverted: boolean,
): number {
  const classes = CabinService.getCabinLafClasses({
    cabinClasses,
  }).map((cls) => cls.code);
  // First sort out the values where there is no LAF, these rows will always be placed in the sort bottom, regardless the sort order
  if (valueA === undefined && valueB === undefined) {
    return 0;
  }
  if (isEmpty(valueA)) {
    return isInverted ? -1 : 1;
  }
  if (isEmpty(valueB)) {
    return isInverted ? 1 : -1;
  }

  // If the LAF's are the same, then order on SeatAvailability
  if (classes.indexOf(valueA) === classes.indexOf(valueB)) {
    if (nodeA && nodeB) {
      const lafSeatAvailabilityA = FlightService.getLafSaForCabin({
        cabinCode,
        flightLine: nodeA.data,
      });

      const lafSeatAvailabilityB = FlightService.getLafSaForCabin({
        cabinCode,
        flightLine: nodeB.data,
      });

      return lafSeatAvailabilityA < lafSeatAvailabilityB ? -1 : 1;
    } else {
      // The filter popover uses the comparator to sort the options. There is no node info available in that case
      // We can just sort on the class structure. So if they are equal, no need to sort.
      return 0;
    }
  }
  // If the LAFs are not the same, order them according to the class structure
  else {
    return classes.indexOf(valueA) < classes.indexOf(valueB) ? 1 : -1;
  }
}

export interface LafCellRendererParams {
  soldOut: boolean;
  lafClass: string;
  lafSa: number | undefined;
  lafColor: string | undefined;
}

function getLafCellRendererParams(
  params: ValueFormatterParams,
  cabinCode: string,
  lafKey: lafKeyType,
  seatAvailabilityKey: seatAvailabilityKeyType,
  cabinClasses: ClassStructure[],
  lafLoadFactorColoring: LafLoadFactorColoring,
): LafCellRendererParams | undefined {
  const colorScheme = generateLafColorScheme(lafLoadFactorColoring, CabinService.getCabinLafClasses({ cabinClasses }));
  const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
  if (matchingCabin) {
    const lafClass: FlightLineCabinClass | undefined = matchingCabin.classes.find((cls) => cls.code === matchingCabin[lafKey]);
    const lafSeatAvailability: number | undefined = lafClass ? lafClass[seatAvailabilityKey] : undefined;
    let lafColor = undefined;
    if (params.value && lafLoadFactorColoring !== LafLoadFactorColoring.OFF) {
      const matchingClass = colorScheme.find((item) => item.class === params.value);
      if (matchingClass) {
        lafColor = matchingClass.color;
      }
    }
    return {
      soldOut:
        !matchingCabin.classes[0] ||
        !!(matchingCabin.classes[0].minCabinSeatAvailability && matchingCabin.classes[0].minCabinSeatAvailability < 1),
      lafClass: matchingCabin[lafKey] as string,
      lafSa: lafSeatAvailability,
      lafColor,
    };
  }
}

function getLafValueGetter(params: ValueGetterParams, cabinCode: string, lafKey: lafKeyType): string {
  const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
  if (
    matchingCabin &&
    (!matchingCabin.classes[0] ||
      (matchingCabin.classes[0].minCabinSeatAvailability && matchingCabin.classes[0].minCabinSeatAvailability < 1))
  ) {
    return '';
  }
  return matchingCabin ? (matchingCabin[lafKey] as string) : '';
}

export const generateCabinSeatAvailabilityColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.SeatAvailability),
  headerName: t('seat_availability_short'),
  type: 'numericColumn',
  minWidth: 35,
  width: 35,
  sortable: true,
  headerTooltip: t('cabin_seat_availability', {
    cabin: cabinCode,
  }),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin && matchingCabin.classes[0]) {
      return matchingCabin.classes[0].minCabinSeatAvailability;
    } else {
      return null;
    }
  },
});

export const generateCabinAutopilotColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.Autopilot),
  headerName: t('autopilot_short'),
  minWidth: 40,
  width: 40,
  sortable: true,
  hide: true,
  type: 'leftAligned',
  cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-autopilot-key-cell-${data.ondId}`,
  headerTooltip: t('autopilot'),
  cellStyle: (params: CellClassParams) => (params.value ? { color: '#409EFF' } : params.value === false ? { color: '#909399' } : undefined),
  valueGetter: (params: ValueGetterParams): boolean => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin?.autopilot ?? false;
  },
  valueFormatter: (params: ValueFormatterParams<FlightLineModel, boolean>): string => (params.value ? t('on') : t('off')),
  filterParams: {
    valueFormatter: (params: ValueFormatterParams<null, string>): string => (params.value === 'true' ? t('on') : t('off')),
  },
});

export const generateCabinLowestAvailableFarePriceColumn = (cabinCode: string, fareSource: FareSource): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-laf-fare-price`,
  headerName: t('own_fare'),
  minWidth: 60,
  width: 60,
  sortable: true,
  type: 'numericColumn',
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    if (params.data.cabins && params.data.cabins.length) {
      return getCabinFare(params.data, cabinCode, fareSource);
    }
    return undefined;
  },
  valueFormatter: (params: ValueFormatterParams) => {
    const fare = params.value;
    if (isFinite(fare)) {
      return FormatService.formatNumber(fare);
    }

    return '';
  },
  headerTooltip: `${t('cabin_x_fare_value', {
    cabin: cabinCode,
  })}`,
});

const getCompetitorFareValue = (params: ValueGetterParams, cabinCode: string, competitorCarrierCode: string) => {
  if (isEmpty(params.data.cabins)) {
    return;
  }

  const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
  if (matchingCabin && matchingCabin.competitiveFares) {
    const competitorData = first(
      FlightService.getSortedFares(matchingCabin.competitiveFares.filter((cf) => cf.carrier === competitorCarrierCode)),
    );
    if (competitorData) {
      return competitorData.fare;
    }
  }
  return;
};

const generateCabinMinCompetitorFareColumn = (cabinCode: string, competitorCarrierCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  headerName: t('min_fare_of_carrier', {
    carrier: competitorCarrierCode,
  }),
  headerClass: `ag-right-aligned-header data-test-header-${cabinCode}-${competitorCarrierCode}-cabin-competitor-fare`,
  headerTooltip: t('min_fare_of_carrier', {
    carrier: competitorCarrierCode,
  }),
  colId: generateCabinLevelMinCompetitorColumnId(cabinCode, competitorCarrierCode, ColumnId.CabinCompetitorFare),
  cellClass: ({ data }: CellClassParams) =>
    `ag-right-aligned-cell data-test-cabin-min-competitor-fare-${competitorCarrierCode}-${data.ondId}`,
  minWidth: 55,
  width: 55,
  sortable: true,
  type: 'numericColumn',
  valueFormatter: (params: ValueFormatterParams) => {
    const value = params.value;

    if (isFinite(value)) {
      return FormatService.amountWithoutCurrency(value, params.data.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => getCompetitorFareValue(params, cabinCode, competitorCarrierCode),
});

export const generateCabinCompetitorFareColumn = (cabinCode: string, competitorCarrierCodes: string[]): ColDef[] => {
  if (isEmpty(competitorCarrierCodes)) {
    return [];
  }

  return competitorCarrierCodes.flatMap((competitorCarrierCode) => {
    const competitorFareColumn: ColDef = {
      ...NumberColumnFilterSettings,
      colId: generateCabinLevelCompetitorColumnId(cabinCode, competitorCarrierCode, ColumnId.CabinCompetitorFare),
      headerName: `${t('delta_min_fare_of_carrier', {
        carrier: competitorCarrierCode,
      })}`,
      headerClass: 'ag-right-aligned-header',
      headerTooltip: `${t('delta_min_fare_of_carrier_explained', {
        carrier: competitorCarrierCode,
      })}`,
      minWidth: 55,
      width: 55,
      sortable: true,
      type: 'numericColumn',
      cellRenderer: 'GridDifferenceRenderer',
      comparator: StringOrNumberComparator,
      valueGetter: (params: ValueGetterParams) => {
        if (isEmpty(params.data.cabins)) {
          return;
        }
        const cabin = FlightService.getMatchedCabin(params.data, cabinCode);

        if (cabin && cabin.competitiveFares) {
          const carrierLowestCf = getCompetitorFareValue(params, cabinCode, competitorCarrierCode);
          const farePrice = getFareForCabin(cabin, CustomerSettingsModule.settings.ownFareSource);

          if (carrierLowestCf && farePrice) {
            return carrierLowestCf - farePrice;
          }
        }

        return;
      },
    };

    return [competitorFareColumn, generateCabinMinCompetitorFareColumn(cabinCode, competitorCarrierCode)];
  });
};

export const generateCabinCompetitiveFareColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-competitive-fare`,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-competitive-fare-${data.ondId}`,
  headerName: `${t('min')} ${t('competitive_fare_short')}`,
  headerClass: `ag-right-aligned-header data-test-header-${cabinCode}-competitive-fare`,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  sortable: true,
  cellRenderer: 'GridInventoryTacticsCompetitorFareCell',
  comparator: StringOrNumberComparator,
  cellRendererParams: (params: ICellRendererParams<FlightLineModel>) => {
    if (params.data) {
      const matchedCabin = FlightService.getMatchedCabin(params.data, cabinCode);

      if (matchedCabin) {
        const farePrice = getFareForCabin(matchedCabin, CustomerSettingsModule.settings.ownFareSource);
        const lowestFare = matchedCabin.competitiveFares ? first(FlightService.getSortedFares(matchedCabin.competitiveFares)) : undefined;

        return {
          cabinCode,
          farePrice,
          lowestFare,
          departureDateTime: DateTimeService.getDate({
            date: params.data.departureDate + 'T' + params.data.departureTime,
            format: Constants.DEFAULT_DATE_FORMAT + 'T' + Constants.DEFAULT_TIME_FORMAT,
          }),
        };
      }
    }
  },
  valueGetter: (params: ValueGetterParams<FlightLineModel>): number | undefined => {
    if (params.data?.cabins?.length) {
      const cabin = params.data.cabins.find((flightLineCabin: FlightLineCabin) => flightLineCabin.code === cabinCode);
      return cabin ? FlightService.getMinCompetitiveFareOfCabin(cabin) : undefined;
    }
  },
  valueFormatter: (params: ValueFormatterParams<FlightLineModel>) => formatNumber(params.value),
  headerTooltip: `${t('min_fare_of_carrier_explained', {
    cabin: cabinCode,
  })}`,
});

export const generateCabinMinRivalFareColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-rival-fare`,
  headerName: `${t('min')} ${t('rival_fare_short')}`,
  headerTooltip: `${t('min')} ${t('rival_fare_short')}`,
  minWidth: 35,
  width: 35,
  type: 'numericColumn',
  sortable: true,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-min-rival-fare-${data.ondId}-${cabinCode}`,
  cellRenderer: 'GridInventoryTacticsRivalFareCell',
  comparator: StringOrNumberComparator,
  cellRendererParams: (params: ICellRendererParams) => {
    const matchedCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchedCabin) {
      const farePrice = getFareForCabin(matchedCabin, CustomerSettingsModule.settings.ownFareSource);
      const lowestFare = matchedCabin.rivalFares ? first(FlightService.getSortedFares(matchedCabin.rivalFares)) : undefined;

      return {
        cabinCode,
        farePrice,
        lowestFare,
        departureDateTime: DateTimeService.getDate({
          date: params.data.departureDate + 'T' + params.data.departureTime,
          format: Constants.DEFAULT_DATE_FORMAT + 'T' + Constants.DEFAULT_TIME_FORMAT,
        }),
      };
    }
  },
  valueGetter: (params: ValueGetterParams) => {
    if (params.data.cabins && params.data.cabins.length) {
      const cabin = params.data.cabins.find((flightLineCabin: FlightLineCabin) => flightLineCabin.code === cabinCode) as FlightLineCabin;
      const minRivalFare = FlightService.getMinRivalFareOfCabin(cabin);
      return minRivalFare || minRivalFare === 0 ? minRivalFare : undefined;
    }
  },
  valueFormatter: ({ value }: ValueFormatterParams<FlightLineModel>) => formatNumber(value),
  requiredPermission(params) {
    return !!params.customerSettings.hasRivalRulesEnabled;
  },
});

export const generateCabinCompetitiveFareDifferenceColumn = (cabinCode: string, fareSource: FareSource): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-competitive-fare-difference`,
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-competitive-fare-difference-${data.ondId}`,
  headerName: t('min_cf_difference_short'),
  headerClass: `ag-right-aligned-header data-test-header-${cabinCode}-competitive-fare-difference`,
  type: 'rightAligned',
  minWidth: 35,
  width: 35,
  sortable: true,
  cellRenderer: 'GridDifferenceRenderer',
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams): number | undefined => {
    if (params.data.cabins && params.data.cabins.length) {
      const cabin = params.data.cabins.find((flightLineCabin: FlightLineCabin) => flightLineCabin.code === cabinCode);

      if (!cabin) {
        return undefined;
      }

      const ownFare = getCabinFare(params.data as FlightDynamicPropertiesAllowed, cabinCode, fareSource);
      const lowestFare = cabin.competitiveFares ? first(FlightService.getSortedFares(cabin.competitiveFares)) : undefined;
      if (lowestFare && ownFare) {
        return lowestFare.fare - ownFare;
      }

      return undefined;
    }

    return undefined;
  },
  headerTooltip: `${t('min_cf_difference', {
    cabin: cabinCode,
  })}`,
});

export const generateCabinRivalFareDifferenceColumn = (cabinCode: string, fareSource: FareSource): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-rival-fare-difference`,
  headerName: t('min_rival_fare_difference_short'),
  type: 'numericColumn',
  minWidth: 20,
  width: 20,
  sortable: true,
  cellRenderer: 'GridDifferenceRenderer',
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams): number | undefined => {
    if (params.data.cabins && params.data.cabins.length) {
      const cabin = params.data.cabins.find((flightLineCabin: FlightLineCabin) => flightLineCabin.code === cabinCode) as FlightLineCabin;

      if (!cabin) return undefined;

      const ownFare = getCabinFare(params.data as FlightDynamicPropertiesAllowed, cabinCode, fareSource);
      const lowestFare = cabin.rivalFares ? first(FlightService.getSortedFares(cabin.rivalFares)) : undefined;

      return lowestFare && ownFare ? lowestFare.fare - ownFare : undefined;
    }

    return undefined;
  },
  headerTooltip: t('min_rival_fare_difference', {
    cabin: cabinCode,
  }),
  requiredPermission(params) {
    return !!params.customerSettings.hasRivalRulesEnabled;
  },
});

export const generateCabinSpillageSpoilageColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: `${cabinCode}-sds`,
  headerName: t('sds_short'),
  width: 100,
  minWidth: 100,
  headerTooltip: t('sds'),
  cellRenderer: 'GridSpillageSpoilageRenderer',
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    const specificCabin = (params.data as FlightDynamicPropertiesAllowed).cabins.find((cabinInGrid) => cabinInGrid.code === cabinCode);
    if (specificCabin && specificCabin.lowestAvailableFareClass) {
      const lf = (specificCabin.maxLegBookings / specificCabin.minLegSaleableCapacity) * 100;
      return Math.round(lf - CalculationService.calculateLafPercentage(specificCabin.code, specificCabin.lowestAvailableFareClass));
    }
    return null;
  },
});

export const generateCabinLoadFactorLafColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  type: 'numericColumn',
  colId: `${cabinCode}-load-factor-laf`,
  headerName: t('lid_lf_laf_short'),
  width: 35,
  minWidth: 80,
  headerTooltip: t('lid_lf_laf'),
  cellRenderer: 'GridLoadFactorLafRenderer',
  sortable: true,
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams) => {
    const specificCabin = (params.data as FlightDynamicPropertiesAllowed).cabins.find((cabinInGrid) => cabinInGrid.code === cabinCode);
    if (specificCabin) {
      return Number(FormatService.roundNumber(specificCabin.lidLoadFactor, 0));
    }
  },
  cellRendererParams: {
    cabinCode: cabinCode,
  },
});

export const CabinCompetitorFaresColumn: ColDef = {
  colId: ColumnId.CabinCompetitorFares,
  headerName: t('competitor_fares'),
  headerTooltip: t('competitor_fares'),
  type: 'numericColumn',
  width: 55,
  minWidth: 55,
  field: 'competitorFarePrices',
  cellRenderer: 'GridInventoryTacticsCompetitorFareCell',
  comparator: StringOrNumberComparator,
  cellRendererParams: (params: ICellRendererParams<FlightViewLegCabinInventoryTactic>): Partial<CompetitorFareGridModel> => {
    const invTacticRow = params.data;

    return {
      cabinCode: invTacticRow?.cabinCode,
      farePrice: invTacticRow?.ownFare,
      lowestFare: invTacticRow?.competitorFarePrices ? invTacticRow.competitorFarePrices[0] : undefined,
      departureDateTime: invTacticRow?.departureDateTime,
    };
  },
  valueGetter: (params: ValueGetterParams<FlightViewLegCabinInventoryTactic>): number | undefined =>
    params.data?.competitorFarePrices?.[0]?.fare,
  valueFormatter: ({ value }: ValueFormatterParams<FlightViewLegCabinInventoryTactic>): string => formatNumber(value),
};

export const CabinCompetitorFaresDifferenceColumn: ColDef = {
  colId: ColumnId.CabinCompetitorFaresDifference,
  headerName: t('min_cf_difference_short'),
  type: 'numericColumn',
  width: 50,
  minWidth: 50,
  field: 'competitorFarePrices',
  cellRenderer: 'GridDifferenceRenderer',
  headerTooltip: t('min_cf_difference'),
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams<FlightViewLegCabinInventoryTactic>): number | undefined => {
    const invTacticRow = params.data;
    const ownFare = invTacticRow?.ownFare;
    const lowestFare = invTacticRow?.competitorFarePrices?.[0];

    return lowestFare && !isNil(ownFare) ? lowestFare.fare - ownFare : undefined;
  },
};

export const CabinRivalFaresColumn: ColDef = {
  colId: ColumnId.CabinRivalFares,
  headerName: t('rival_fares'),
  headerTooltip: t('rival_fares'),
  type: 'numericColumn',
  width: 55,
  minWidth: 55,
  field: 'rivalFarePrices',
  cellRenderer: 'GridInventoryTacticsRivalFareCell',
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-rival-fares-${data.id}`,
  cellRendererParams: (params: ICellRendererParams): Partial<RivalFareGridModel> => {
    const invTacticRow = params.data as FlightViewLegCabinInventoryTactic;
    return {
      cabinCode: invTacticRow.cabinCode,
      farePrice: invTacticRow.ownFare,
      lowestFare: invTacticRow.rivalFarePrices?.[0],
      departureDateTime: invTacticRow.departureDateTime,
    };
  },
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams): number | undefined => {
    const invTacticRow = params.data as FlightViewLegCabinInventoryTactic;
    return invTacticRow.rivalFarePrices?.[0]?.fare;
  },
  valueFormatter: ({ value }: ValueFormatterParams) => formatNumber(value),
};

export const CabinRivalFaresDifferenceColumn: ColDef = {
  colId: ColumnId.CabinRivalCompetitorFareDifference,
  headerName: t('min_rival_fare_difference_short'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-rival-fare-difference-${data.id}`,
  type: 'numericColumn',
  width: 50,
  minWidth: 50,
  field: 'rivalFarePrices',
  cellRenderer: 'GridDifferenceRenderer',
  headerTooltip: t('min_rival_fare_difference'),
  comparator: StringOrNumberComparator,
  valueGetter: (params: ValueGetterParams): number | undefined => {
    const invTacticRow = params.data as FlightViewLegCabinInventoryTactic;
    const ownFare = invTacticRow.ownFare;
    const lowestFare = invTacticRow.rivalFarePrices ? invTacticRow.rivalFarePrices[0] : undefined;
    if (lowestFare && !isNil(ownFare)) {
      return lowestFare.fare - ownFare;
    } else {
      return undefined;
    }
  },
};

export type AutopilotColumnCallback = ({
  value,
  cabinCode,
  flightLineId,
}: {
  value: boolean;
  cabinCode: string;
  flightLineId: number;
}) => void;

const autopilotColumnCallback: AutopilotColumnCallback = ({ value, cabinCode, flightLineId }) => {
  const action = new UpdateAutopilotAction(cabinCode);
  action.setPayload({
    actionType: FlightActionType.updateAutopilot,
    cabinCode,
    value,
  });

  logger.trackEvent(`Autopilot ${value ? 'On' : 'Off'}`);

  FlightModule.addAutopilotAction({
    action,
    flightLineId,
  });
};

export const AutopilotColumn: ColDef = {
  colId: ColumnId.Autopilot,
  headerName: t('autopilot'),
  field: 'autopilot',
  type: 'leftAligned',
  width: 70,
  minWidth: 70,
  cellRenderer: 'GridSwitchRenderer',
  cellRendererParams: {
    onChange: autopilotColumnCallback,
  },
  headerTooltip: t('autopilot'),
};

export type OptimizationTacticColumnSwap = ({
  value,
  cabinCode,
  flightLineId,
  autopilot,
}: {
  value: any;
  cabinCode: string;
  flightLineId: number;
  autopilot: boolean;
}) => void;

const optimizationTacticColumnSwap: OptimizationTacticColumnSwap = ({ cabinCode, flightLineId, autopilot }) => {
  FlightModule.addSwapOptimisationProfileAction({ cabinCode, flightLineId, autopilot });
};

export type OptimizationTacticColumnCallback = ({
  value,
  cabinCode,
  flightLineId,
  autopilot,
}: {
  value: any;
  cabinCode: string;
  flightLineId: number;
  autopilot: boolean;
}) => void;

const optimizationTacticColumnCallback: OptimizationTacticColumnCallback = ({ value, cabinCode, flightLineId, autopilot }) => {
  const action = new AddOptimizationProfileLevelAction(cabinCode, autopilot, value);
  logger.trackEvent('Optimisation Tactic Change', { action });
  FlightModule.addRemoveOptimizationTacticAction({
    action,
    flightLineId,
    shadow: false,
  });
};

const shadowOptimizationTacticColumnCallback: OptimizationTacticColumnCallback = ({ value, cabinCode, flightLineId }) => {
  const action = new AddShadowOptimizationProfileLevelAction(cabinCode, value);
  logger.trackEvent('Shadow Optimisation Tactic Change', { action });
  FlightModule.addRemoveOptimizationTacticAction({
    action,
    flightLineId,
    shadow: true,
  });
};

export enum SwapType {
  ToActive = 'toActive',
  ToShadow = 'toShadow',
}

export const OptimizationTacticColumn: ColDef = {
  colId: ColumnId.OptimizationTactic,
  headerName: t('optimisation_tactics_short'),
  field: 'optimisationProfileLevelId',
  minWidth: 80,
  cellRenderer: 'GridOptimisationTacticRenderer',
  cellRendererParams: (params: ValueFormatterParams) => ({
    onSwap: optimizationTacticColumnSwap,
    onChange: optimizationTacticColumnCallback,
    optimisationTactics: (params.data as FlightViewLegCabinInventoryTactic).optimisationTactics,
    swapType: SwapType.ToShadow,
  }),
  headerClass: `ag-left-aligned-header`,
  cellClass: 'marginless-cell ag-left-aligned-cell',
  headerTooltip: t('optimisation_tactics'),
};

export const ShadowTacticsOptimizationTacticColumn: ColDef = {
  colId: ColumnId.ShadowTacticsOptimizationTactic,
  headerName: t('optimisation_tactics_short'),
  field: 'shadowOptimisationProfileLevelId',
  minWidth: 80,
  cellRenderer: 'GridOptimisationTacticRenderer',
  cellRendererParams: (params: ValueFormatterParams) => ({
    onSwap: optimizationTacticColumnSwap,
    onChange: shadowOptimizationTacticColumnCallback,
    optimisationTactics: (params.data as FlightViewLegCabinInventoryTactic).shadowOptimisationTactics,
    swapType: SwapType.ToActive,
  }),
  headerClass: `ag-left-aligned-header`,
  cellClass: 'marginless-cell ag-left-aligned-cell',
  headerTooltip: t('optimisation_tactics'),
};

export type PricingAdjustmentColumnCallback = ({
  value,
  cabinCode,
  flightLineId,
}: {
  value: number;
  cabinCode: string;
  flightLineId: number;
}) => void;

const pricingAdjustmentColumnCallback: PricingAdjustmentColumnCallback = ({ value, cabinCode, flightLineId }) => {
  const action = new SetPricingAdjustmentAction(cabinCode, value);

  FlightModule.addAction({
    action,
    flightLineId,
  });
};

export const PricingAdjustmentColumn: ColDef = {
  colId: ColumnId.PricingAdjustment,
  headerName: t('tactic_adjustment_short'),
  type: 'numericColumn',
  field: 'pricingAdjustment',
  minWidth: 156,
  width: 156,
  cellRenderer: 'GridTacticAdjustmentRenderer',
  cellClass: 'marginless-cell',
  headerTooltip: t('tactic_adjustment'),
  cellRendererParams: {
    onChange: pricingAdjustmentColumnCallback,
  },
};

export const PricingTacticColumn: ColDef = {
  colId: ColumnId.PricingTactic,
  headerName: t('pricing_tactic'),
  type: 'numericColumn',
  field: 'pricingTactic',
  minWidth: 120,
  width: 120,
  cellRenderer: 'GridPricingTacticRenderer',
  cellClass: 'marginless-cell',
  headerTooltip: t('pricing_tactic'),
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewLegCabinInventoryTactic;

    const action = new SetPricingTacticAction(data.cabinCode, event.newValue);

    FlightModule.addAction({
      action,
      flightLineId: data.id,
    });
  },
};

export const PricingAggregationColumn: ColDef = {
  colId: ColumnId.PricingAggregation,
  headerName: t('price_increment_short'),
  type: 'numericColumn',
  field: 'pricingIncrement',
  minWidth: 75,
  width: 75,
  cellRenderer: 'GridPricingAggregationRenderer',
  cellClass: 'marginless-cell',
  headerTooltip: t('price_increment'),
  onCellValueChanged: ({ newValue, data: { cabinCode, id: flightLineId } }: NewValueParams) => {
    const action = new SetPricingIncrementAction(newValue, cabinCode);
    FlightModule.addAction({ action, flightLineId });
  },
};

export const PromotionColumn: ColDef = {
  colId: ColumnId.Promotion,
  headerName: t('promotion'),
  type: 'numericColumn',
  field: 'promotion',
  minWidth: 80,
  width: 100,
  cellRenderer: 'GridPromotionRenderer',
  cellClass: 'marginless-cell',
  headerTooltip: t('promotion'),
  onCellValueChanged: (event: NewValueParams) => {
    const data = event.data as FlightViewLegCabinInventoryTactic;
    const value = event.newValue;
    let action;

    // No value means user removed promotion
    if (isEmpty(value)) {
      action = new RemovePromotionAction(data.cabinCode);

      FlightModule.removeAction({
        actionType: FlightActionType.setPromotion,
        flightLineId: data.id,
      });
    } else {
      action = new SetPromotionAction(data.cabinCode, [value[0], value[1]]);

      FlightModule.removeAction({
        actionType: FlightActionType.removePromotion,
        flightLineId: data.id,
      });
    }

    FlightModule.addAction({
      action,
      flightLineId: data.id,
    });
  },
};

export const CabinSoldColumn: ColDef = {
  colId: ColumnId.CabinSold,
  headerName: t('sold_short'),
  type: 'numericColumn',
  field: 'sold',
  width: 45,
  minWidth: 45,
  headerTooltip: t('sold'),
};

export const CabinCodeColumn: ColDef<FlightViewLegCabinInventoryTactic> = {
  colId: ColumnId.CabinCode,
  headerName: t('cabin_short'),
  field: 'cabinCode',
  type: 'leftAligned',
  suppressSizeToFit: true,
  lockPosition: true,
  lockPinned: true,
  lockVisible: true,
  minWidth: 40,
  width: 40,
  headerTooltip: t('cabin'),
};

export const generateMaxLegBookingsColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.MaxLegBookings),
  headerName: t('max_leg_bk_short'),
  field: 'maxLegBookings',
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('max_leg_bk'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin ? matchingCabin.maxLegBookings : undefined;
  },
});

export const generateCabinBookingsColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinBookings),
  headerName: t('bookings_short'),
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('bookings'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);

    if (!matchingCabin) {
      return undefined;
    }
    return matchingCabin.realTimeBookings !== undefined ? matchingCabin.realTimeBookings : matchingCabin.bookings;
  },
});

export const generateCabinCapacityColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinAuthorizedCapacity),
  headerName: t('general.capacity_short'),
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('general.capacity'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin ? matchingCabin.minLegSaleableCapacity : undefined;
  },
});

export const generateCabinLidColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinSaleableCapacity),
  headerName: t('general.lid_short'),
  type: 'numericColumn',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('general.lid'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin ? matchingCabin.minLegAuthorizedCapacity : undefined;
  },
});

export const generateCabinCurrencyColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: `${cabinCode}-currency`,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-currency-${data.ondId}`,
  headerName: t('general.cabin_currency_short'),
  headerTooltip: t('general.cabin_currency'),
  field: 'fareCurrency',
  minWidth: 35,
  width: 35,
  sortable: true,
  type: 'leftAligned',
});

export function generateCabinSummedRevenueColumn(cabinCode: string): ColDef<FlightLineModel> {
  return {
    ...NumberColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinSummedRevenue),
    headerName: t('revenue_short'),
    headerTooltip: t('revenue'),
    type: 'numericColumn',
    width: 40,
    minWidth: 40,
    sortable: true,
    comparator: StringOrNumberComparator,
    valueFormatter: (params) => {
      const revenue = params.value;
      if (revenue || revenue === 0) {
        return FormatService.amountWithoutCurrency(revenue, params?.data?.fareCurrency);
      }

      return '';
    },
    valueGetter: (params: ValueGetterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      return matchingCabin?.totalRevenue?.fare?.amount && Math.round(matchingCabin.totalRevenue?.fare?.amount);
    },
  };
}

export const generateCabinAverageFareColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.AverageFare),
  headerName: t('average_fare_short'),
  field: InventoryGridModel.averageFare,
  minWidth: 40,
  width: 40,
  type: 'numericColumn',
  hide: false,
  sortable: true,
  headerTooltip: t('average_fare'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const averageFare = params.value;

    if (averageFare || averageFare === 0) {
      return FormatService.amountWithoutCurrency(averageFare, params.data.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && Math.round(matchingCabin.averageFare);
  },
});

export const generateCabinLafFareValueColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinLafFareValue),
  headerName: t('laf_fare_value_short'),
  type: 'numericColumn',
  field: 'lafFareValue',
  width: 35,
  minWidth: 35,
  sortable: true,
  headerTooltip: t('laf_fare_value'),
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const lafFareValue = params.value;

    if (lafFareValue || lafFareValue === 0) {
      return FormatService.amountWithoutCurrency(lafFareValue, params.data.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.lafFareValue;
  },
});

export const generateCabinNegotiatedAllocationsColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinNegoSpace),
  headerName: t('nego_space'),
  headerTooltip: t('negotiated_space'),
  type: 'numericColumn',
  minWidth: 45,
  cellRenderer: 'GridNegotiatedAllocationsRenderer',
  sortable: true,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin.negotiatedAllocations && matchingCabin.negotiatedAllocations.totalSoldSeats;
  },
});

export const generateCabinPinnedClassesColumn = (cabinCode: string, cabinClasses: ClassStructure[]): ColDef => {
  // Prepare the options in the filter option dropdown box
  const classes = CabinService.getCabinLafClasses({
    cabinClasses,
  }).map((cls) => cls.code);

  return {
    ...TextColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.PinnedClasses),
    headerName: t('pinned_classes_short'),
    type: 'leftAligned',
    width: 50,
    minWidth: 50,
    sortable: true,
    headerTooltip: t('pinned_classes'),
    valueGetter: (params: ValueGetterParams) => {
      const flight = params.data as FlightLineModel;
      const amountOfPins = flight.pins;

      if (amountOfPins && amountOfPins > 0) {
        const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
        if (matchingCabin) {
          const classCodesWithPins = matchingCabin.classes
            .filter((cls) => cls.isProtectionPinned || cls.isAuthorizationUnitPinned)
            .map((cls) => cls.classCode);

          return classCodesWithPins.join(', ');
        }
      }
      return undefined;
    },
    comparator: (valueA: any, valueB: any, nodeA: RowNode, nodeB: RowNode, isInverted) => {
      // If there are values without pins
      if (isEmpty(valueA) && isEmpty(valueB)) {
        return 0;
      }
      if (isEmpty(valueA)) {
        return isInverted ? -1 : 1;
      }
      if (isEmpty(valueB)) {
        return isInverted ? 1 : -1;
      }

      // Otherwise, compare the first/highest class in the cabin
      const firstClassA = classes.indexOf(valueA[0]);
      const firstClassB = classes.indexOf(valueB[0]);

      if (firstClassA < firstClassB) {
        return 1;
      }
      if (firstClassA > firstClassB) {
        return -1;
      }
      if (firstClassA === firstClassB) {
        return 0;
      }

      return 0;
    },
  };
};

export const generateCabinPinnedClassesCountColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.Pins),
  headerName: t('pin_count_column_header'),
  width: 40,
  minWidth: 40,
  type: 'numericColumn',
  hide: false,
  sortable: true,
  headerClass: `ag-left-aligned-header`,
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-pin-count-key-cell-${data.ondId}`,
  headerTooltip: t('pins_count'),
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin?.sumOfPinnedClasses;
  },
});

export const generateCabinPerformanceBandPickupCols = (pickupDays: number[]): ColDef[] => {
  if (isEmpty(pickupDays)) {
    return [];
  }

  return pickupDays.map((pickupDay: number) => {
    const pickupColumn: ColDef = {
      ...NumberColumnFilterSettings,
      headerName: translatePerformanceBandPickupHeader(pickupDay),
      cellRenderer: 'GridDifferenceRenderer',
      minWidth: 25,
      width: 25,
      sortable: false,
      hide: false,
      colId: generateCabinPerfomanceBandPickupColumnId(pickupDay),
      cellClass: 'ag-right-aligned-cell marginless-cell',
      headerTooltip: translatePerformanceBandPickupHeaderTooltip(pickupDay),
      comparator: StringOrNumberComparator,
      valueGetter: (params: ValueGetterParams) => {
        const data = (params.data as FlightViewLegCabinInventoryTactic).performanceBandPickUps?.find(
          (pickup: PerformanceBandPickupModel) => pickup.dayOffset === pickupDay,
        );
        return data?.performanceBandDifference;
      },
    };
    return pickupColumn;
  });
};
