import { InventoryManagementType } from '@/modules/shared/shared-contracts';

export interface FieldSettings {
  isVisible: boolean;
  isMandatory: boolean;
}
export interface InventoryManagementSettings {
  type: InventoryManagementType;
  airlineCode: FieldSettings;
  flightNumber: FieldSettings;
  origin: FieldSettings;
  destination: FieldSettings;
  departureDate: FieldSettings;
  flightLine: FieldSettings;
  showAll: FieldSettings;
}

export const LegConfig: InventoryManagementSettings = {
  type: InventoryManagementType.Leg,
  airlineCode: { isVisible: true, isMandatory: false },
  flightNumber: { isVisible: true, isMandatory: false },
  origin: { isVisible: true, isMandatory: false },
  destination: { isVisible: true, isMandatory: false },
  departureDate: { isVisible: true, isMandatory: true },
  flightLine: { isVisible: false, isMandatory: false },
  showAll: { isVisible: false, isMandatory: false },
};

export const SegmentConfig: InventoryManagementSettings = {
  type: InventoryManagementType.Segment,
  airlineCode: { isVisible: true, isMandatory: true },
  flightNumber: { isVisible: true, isMandatory: true },
  origin: { isVisible: false, isMandatory: false },
  destination: { isVisible: false, isMandatory: false },
  departureDate: { isVisible: true, isMandatory: true },
  flightLine: { isVisible: false, isMandatory: false },
  showAll: { isVisible: false, isMandatory: false },
};

export const NetworkConfig: InventoryManagementSettings = {
  type: InventoryManagementType.Network,
  airlineCode: { isVisible: false, isMandatory: false },
  flightNumber: { isVisible: true, isMandatory: true },
  origin: { isVisible: true, isMandatory: false },
  destination: { isVisible: true, isMandatory: false },
  departureDate: { isVisible: true, isMandatory: true },
  flightLine: { isVisible: true, isMandatory: false },
  showAll: { isVisible: true, isMandatory: false },
};
