export const en = {
  details: {
    invalid_fields: {
      flight_number_not_in_suggestions: 'Flight number not in suggested flight numbers',
    },
    ndo_info:
      'Click the arrows to search for a valid departure date. Eddy searches consecutive seven-day periods and displays the first valid flight it finds. The search will stop if no flight can be found within the seven-day period.',
    expected_no_shows: 'Expected No Shows',
    expected_no_shows_short: 'Expected NS',
    overbooking_autopilot: 'Overbooking Autopilot',
    no_overbooking_risk_values_found: 'No Overbooking Risk Values for this Flight have been found',
    overbooking_autopilot_short: 'OVB Autopilot',
    overbooking_autopilot_tooltip:
      'Turning on OVB Autopilot will apply the recommended overbooking factor to the overbooking factor immediately, and each day',
    overbooking_factor_mode: 'Overbooking Factor Mode',
    overbooking_risk: 'Overbooking Risk',
    overbooking_risk_values: 'Overbooking Risk Values',
    overbooking_risk_mode: 'Risk Mode',
    overbooking_tactic: 'Overbooking Tactic',
    overbooking_tactic_short: 'OVB Tactic',
    recommended_authorized_capacity: 'Recommended Authorized Capacity',
    recommended_overbooking_factor_short: 'rOVB Factor',
    recommended_overbooking_factor: 'Recommended Overbooking Factor',
    set_recommended_overbooking_and_apply_to_overbooking: 'Set rOVB and apply to OVB Factor',
    set_recommended_overbooking: 'Set rOVB',
    apply_recommended_overbooking: 'Apply rOVB',
    view_overbooking_risk_values: 'View Overbooking Risk Values',
    forecast_bandwidth: 'Forecast Range',
    forecast: 'Forecast',
    error_loading_forecast_data: 'Error loading forecast data',
  },
};
