import { orderBy } from 'lodash-es';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { CabinStructure, ClassStructure } from '@/modules/api/application/application-contracts';
import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { UpdateAuthorizationUnitsAction } from '@/modules/flight-actions/actions/cabin-actions/update-authorization-units-action';
import { UpdateProtectionAction } from '@/modules/flight-actions/actions/cabin-actions/update-protections-action';
import { FlightActionGroup } from '@/modules/flight-actions/api/flight-action-groups-contracts';
import { flightActionGroupsService } from '@/modules/flight-actions/api/flight-action-groups-service';
import { BaseFlightActionPayload, FlightAction, FlightActionType, PinState } from '@/modules/flight-actions/api/flight-actions-contracts';
import { flightActionService } from '@/modules/flight-actions/api/flight-actions-service';
import { FilterFieldDefinition, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { ITag, TagsModule } from '@/modules/tags';
import { store } from '@/store';
import { AppSettingsModule } from '@/store/modules/app-settings.module';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';
import { FlightModule } from '@/store/modules/flight.module';

// State definition
export interface IFlightActionsState {
  actionsCompleted: boolean;
  flightActionGroups: FlightActionGroup[];
}

function findAction(flightActionType: FlightActionType, flightActions: BaseFlightActionPayload[]): BaseFlightActionPayload {
  return flightActions.find((action) => action.actionType === flightActionType);
}

@Module({ dynamic: true, store, name: 'flightActions', namespaced: true })
class FlightActions extends VuexModule implements IFlightActionsState {
  // Default state
  public actionsCompleted = true;
  public flightActionGroups: FlightActionGroup[] = [];
  public flightActions: FlightAction<any>[] = [];

  // Actions
  @Action
  public async apply(payload: { actions: FilterFieldDefinition[]; flightLines: FlightLineModel[] }) {
    this.setActionsCompleted(false);

    const response = await flightActionService.applyActions(payload.actions as FlightActionDefinition[], payload.flightLines, {
      inventoryConfigurationProperties: AppSettingsModule.inventoryConfigurationProperties,
      pssCapabilities: CustomerSettingsModule.settings.pssCapabilities,
      priceIncrementRange: CustomerSettingsModule.settings.priceIncrementRange,
    });

    this.setActionsCompleted(true);

    /**
     * Fetch new tags if tags are created as a result of the actions being applied
     */
    const createdNewTags = (payload.actions as FlightActionDefinition[])
      .filter(({ flightActionType }) => flightActionType === FlightActionType.tagsAdd)
      .flatMap(({ value }) => value.filter((tag: ITag | string) => typeof tag === 'string'));

    if (createdNewTags.length) {
      TagsModule.get();
    }

    return response;
  }

  @Action
  public async applyOne(payload: {
    actions: FlightAction<any>[];
    flightLine: FlightLineModel;
    preview: boolean;
    updateWorkingFlight: boolean;
  }): Promise<FlightLineModel> {
    const actions = [...payload.actions];

    try {
      this.setActionsCompleted(false);
      // AU / PR action should be added at the end since we might have pinned actions -> Pin is King
      const auAction = findAction(FlightActionType.updateAuthorizationUnits, actions) as UpdateAuthorizationUnitsAction;

      if (auAction && auAction.value.classes.some((clazz) => clazz.pinned === PinState.PIN)) {
        const index = payload.actions.findIndex((action) => action.actionType === FlightActionType.updateAuthorizationUnits);
        actions.splice(index, 1);
        actions.push(auAction);
      }

      const prAction = findAction(FlightActionType.updateProtection, actions) as UpdateProtectionAction;

      if (prAction && prAction.value.classes.some((clazz) => clazz.pinned === PinState.PIN)) {
        const index = payload.actions.findIndex((action) => action.actionType === FlightActionType.updateProtection);
        actions.splice(index, 1);
        actions.push(prAction);
      }

      // sort all classes per cabin, so we can sort on this per action later
      const classesPerCabin: { [key: string]: ClassStructure[] } = {};
      AppSettingsModule.inventoryConfigurationProperties.cabins.forEach((cabin: CabinStructure) => {
        classesPerCabin[cabin.code] = cabin.classes;
      });

      const classOrder: (flightAction: FlightAction<any>) => number = function (flightAction: FlightAction<any>) {
        return classesPerCabin[flightAction.cabinCode].find(
          (classStructure: ClassStructure) => classStructure.code === flightAction.value?.classes?.[0].code,
        )?.order;
      };

      const orderedExecutedActions = actions
        .sort((a, b) => {
          // Check if the action has a cabinCode, if not, keep the order as is.
          if (!a.cabinCode || !b.cabinCode) {
            return 0;
          }
          // Make sure the actions are sorted on each cabin's class order, so top classes actions are handled first in each cabin, and then work down.
          // The order overlaps cabins, so the order already takes in account if there are several cabins.
          // e.g. If cabin Y has class B with order 0, then another cabin can't have any class with order 0 anymore.
          return classOrder(a) < classOrder(b) ? -1 : 1;
        })
        .map(async (action) => action.getPayload());
      const response = await flightActionService.applyActionsById(
        await Promise.all(orderedExecutedActions),
        payload.flightLine,
        payload.preview,
      );

      if (payload.updateWorkingFlight) {
        FlightModule.updateWorkingFlight(response);
        FlightModule.setSelectedFlightLine(FlightModule.selectedFlightLineCode);
      }

      return response;
    } finally {
      this.setActionsCompleted(true);
    }
  }

  @Action
  public async getFlightActionGroups() {
    try {
      const flightActionGroups = await flightActionGroupsService.getAll();
      this.setFlightActionGroups(flightActionGroups);
    } catch (error) {
      this.setFlightActionGroups([]);
    }
  }

  @Action
  public async updateFlightActionGroups(actionGroups: FlightActionGroup[]) {
    try {
      const flightActionGroups = await flightActionGroupsService.saveAll(actionGroups);
      this.setFlightActionGroups(flightActionGroups);
    } catch (error) {
      this.setFlightActionGroups([]);
    }
  }

  // Mutations
  @Mutation
  private setActionsCompleted(actionsCompleted: boolean) {
    this.actionsCompleted = actionsCompleted;
  }

  @Mutation
  private setFlightActionGroups(flightActionGroups: FlightActionGroup[]) {
    this.flightActionGroups = orderBy([...flightActionGroups], 'groupOrder');
  }
}

export const FlightActionsModule = getModule(FlightActions);
