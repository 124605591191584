import { isFinite } from 'lodash-es';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { MessageService } from '@/modules/shared';
import { store } from '@/store';

import { ClusterModel } from '../api/cluster-contracts';
import { clusterService } from '../api/cluster-service';

export interface IClusterManagementState {
  allClusters: ClusterModel[];
  editingCluster: ClusterModel | null;
  isLoading: boolean;
  isEditingCluster: boolean;
}

@Module({ dynamic: true, store, name: 'cluster-management', namespaced: true })
class ClusterManagement extends VuexModule implements IClusterManagementState {
  public isLoading = false;
  public allClusters: ClusterModel[] = [];
  public editingCluster: ClusterModel | null = null;
  public isEditingCluster = false;

  @Action
  public async getAllClusters(): Promise<void> {
    this.setLoadingState(true);
    this.setAllClusters(await clusterService.getAll());
    this.setLoadingState(false);
  }

  @Action
  public async saveCluster(cluster: ClusterModel): Promise<void> {
    this.setLoadingState(true);
    try {
      if (!isFinite(cluster.id)) {
        await clusterService.create(cluster);
      } else {
        await clusterService.update(cluster.id as number, cluster);
      }
    } catch (error) {
      MessageService.failedRequest();
    }

    await this.getAllClusters();
  }

  @Mutation
  public setAllClusters(allClusters: ClusterModel[]): void {
    this.allClusters = allClusters;
  }

  @Mutation
  public setLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @Mutation
  public setEditingCluster(editingCluster: ClusterModel): void {
    this.editingCluster = editingCluster;
  }

  @Mutation
  public setIsEditingCluster(isEditing: boolean): void {
    this.isEditingCluster = isEditing;
  }
}

export const ClusterManagementModule = getModule(ClusterManagement);
