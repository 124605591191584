import moment, { Moment } from 'moment/moment';

import { Constants } from '@/constants';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { DateTimeService } from '@/services/date-time.service';
import { FilterService } from '@/services/filter.service';
import { ConfigurationModule } from '@/store/modules/configuration.module';

export const dayOfWeekFilter: FilterFieldDefinition = {
  field: FilterFieldField.dayOfWeek,
  type: FilterFieldType.in,
  value: [],
  componentDataOptions: moment.weekdaysShort().map((dow, index) => ({
    id: index === 0 ? 7 : index,
    name: dow,
  })),
  generateCustomFilter: (item: FilterFieldDefinition) => {
    if (item.value && item.value.length > 0) {
      return {
        field: item.field,
        type: FilterFieldType.in,
        value: (item.value as { id: number; name: string }[]).map((val) => val.id).filter((val) => val !== undefined),
      };
    } else {
      return null;
    }
  },
  componentValueIsString: false,
  componentCustomTemplate: false,
  customTemplateFieldKeys: [],
  componentTitle: true,
  multiSelect: true,
  valueKey: 'id',
  transformToParams: (value: { id: number; name: string }[] | number[]) =>
    isNaN(value[0] as number) ? (value as { id: number; name: string }[]).map((dow) => dow.id).join(',') : (value as number[]).join(','),
  transformFromParams: (value: string) =>
    moment
      .weekdaysShort(true)
      .map((dow, index) => {
        // Get the non-zero (1-7) day of week number
        const dayOfWeekNumber = index + 1;
        if (value.split(',').includes(dayOfWeekNumber.toString())) {
          return {
            id: dayOfWeekNumber,
            name: dow,
          };
        }
      })
      .filter((dow) => dow !== undefined),
};

export const originFilter: FilterFieldDefinition = {
  field: FilterFieldField.origin,
  type: FilterFieldType.in,
  value: [],
  componentDataOptions: [],
  componentValueIsString: true,
  componentCustomTemplate: true,
  customTemplateFieldKeys: ['origin', 'airportOriginName'],
  componentTitle: true,
  multiSelect: true,
  valueKey: FilterFieldField.origin,
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string) => value.split(','),
};

export const destinationFilter: FilterFieldDefinition = {
  field: FilterFieldField.destination,
  type: FilterFieldType.in,
  value: [],
  componentDataOptions: [],
  componentValueIsString: true,
  componentCustomTemplate: true,
  customTemplateFieldKeys: ['destination', 'airportDestinationName'],
  componentTitle: true,
  multiSelect: true,
  valueKey: FilterFieldField.destination,
  transformToParams: (value: string[]) => value.join(','),
  transformFromParams: (value: string) => value.split(','),
};

export const departureDateRangeFilter: FilterFieldDefinition = {
  componentTitle: true,
  field: FilterFieldField.departureDateRange,
  type: FilterFieldType.between,
  value: [],
  generateCustomFilter: (item: FilterFieldDefinition) => {
    const startDate = DateTimeService.StripUTCOffset({
      date: item.value[0],
      format: Constants.DEFAULT_DATE_FORMAT,
    })
      .startOf('day')
      .format(Constants.DEFAULT_DATE_FORMAT + 'T' + Constants.DEFAULT_TIME_FORMAT);
    return {
      field: FilterFieldField.departureDate,
      type: item.value[0].format() === item.value[1].format() ? FilterFieldType.equal : item.type,
      value:
        item.value[0].format() === item.value[1].format()
          ? startDate
          : [
              startDate,
              DateTimeService.StripUTCOffset({
                date: item.value[1],
                format: Constants.DEFAULT_DATE_FORMAT,
              })
                .startOf('day')
                .format(Constants.DEFAULT_DATE_FORMAT + 'T' + Constants.DEFAULT_TIME_FORMAT),
            ],
    };
  },
  isDateDisabled: (date: Date): boolean => moment(date).isBefore(moment(ConfigurationModule.captureDate)),
  transformToParams: (value: any, type: FilterFieldType, urlParams) => {
    let paramValue: string;
    if (type === FilterFieldType.ndo) {
      const captureDate = ConfigurationModule.captureDate || DateTimeService.formatDate();

      const startDay = DateTimeService.StripUTCOffset({
        date: new Date(captureDate),
        format: Constants.DEFAULT_DATE_FORMAT,
      })
        .add(value[0], 'd')
        .format(Constants.DEFAULT_DATE_FORMAT);

      const endDay = DateTimeService.StripUTCOffset({
        date: new Date(captureDate),
        format: Constants.DEFAULT_DATE_FORMAT,
      })
        .add(value[1], 'd')
        .format(Constants.DEFAULT_DATE_FORMAT);

      paramValue = [startDay, endDay].join(',');
    } else {
      /**
       * TODO: Get rid of these multiple formats for date values
       */
      if (typeof value === 'string') {
        const date = getFormattedDate(value);
        paramValue = `${date},${date}`;
      } else {
        paramValue = (value as Moment[]).map((momentDate: Moment) => getFormattedDate(momentDate)).join(',');
      }
    }
    // TODO: We need to get rid of the FilterFieldField.departureDate,
    //  but for now we need to set the param explicitly to the departureDateRange
    urlParams[FilterFieldField.departureDateRange] = paramValue;
    return paramValue;
  },
  transformFromParams: (value: string) => value.split(',').map((strDate: string) => DateTimeService.StripUTCOffset({ date: strDate })),
};

export const flightNumberFilter: FilterFieldDefinition = {
  field: FilterFieldField.flightNumber,
  type: FilterFieldType.equal,
  value: '',
  generateCustomFilter: (item: FilterFieldDefinition) => FilterService.transformFlightNumbersToFilterFields(item.value),
  transformToParams: (value: string | string[], type: FilterFieldType) => {
    if (type === FilterFieldType.between) {
      return (value as string[]).join('-');
    } else {
      return value.toString();
    }
  },
  transformFromParams: (value: string) => value,
};

function getFormattedDate(date: Date | Moment | string): string {
  return DateTimeService.StripUTCOffset({
    date: date,
    format: Constants.DEFAULT_DATE_FORMAT,
  })
    .startOf('day')
    .format(Constants.DEFAULT_DATE_FORMAT);
}
