import { ColDef, RowNode, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { round } from 'lodash-es';

import { SetColumnFilterSettings } from '@/models/columns/definitions/base';
import * as Columns from '@/models/columns/definitions/index';
import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { ClassStructure } from '@/modules/api/application/application-contracts';
import { CabinService } from '@/modules/shared/services/cabin.service';
import { RecommendedLowestAvailableFareComparator, StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';
import { FormatService } from '@/services/format.service';

const { t } = i18n.global;

export const generateCabinRecommendedLowestAvailableFareDataScienceColumn = (cabinCode: string, cabinClasses: ClassStructure[]): ColDef => {
  // Prepare the options in the filter option dropdown box
  const classes: string[] = CabinService.getCabinLafClasses({
    cabinClasses,
  }).map((cls) => cls.code);
  return {
    ...SetColumnFilterSettings,
    colId: generateCabinLevelColumnId(cabinCode, ColumnId.RecommendedLaf),
    headerName: t('recommended_lowest_available_fare_data_science_short'),
    headerTooltip: t('recommended_lowest_available_fare_data_science'),
    type: 'numericColumn',
    cellRenderer: 'GridRecommendedLowestAvailableFareRenderer',
    minWidth: 68,
    width: 68,
    sortable: true,
    menuTabs: ['filterMenuTab'],
    filter: true,
    filterParams: {
      newRowsAction: 'keep',
    },
    comparator: (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode, isInverted: boolean) =>
      RecommendedLowestAvailableFareComparator(valueA, valueB, nodeA, nodeB, isInverted, classes, cabinCode),
    cellRendererParams: (params: ValueFormatterParams) => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (matchingCabin && matchingCabin.dataScienceRecommendation) {
        return {
          rlafClass: matchingCabin.dataScienceRecommendation.recommendedLAF,
          rlafSa: matchingCabin.dataScienceRecommendation.recommendedSeatsAvailable,
        };
      } else {
        return undefined;
      }
    },
    valueGetter: (params: ValueGetterParams): any => {
      const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
      if (matchingCabin && matchingCabin.dataScienceRecommendation) {
        return matchingCabin.dataScienceRecommendation.recommendedLAF;
      } else {
        return undefined;
      }
    },
    requiredPermission(params) {
      return !!params.customerSettings.hasAirAsiaDataScienceIntegrationEnabled;
    },
  };
};

export const generateCabinForecastedLoadFactorColumn = (cabinCode: string): ColDef => ({
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ForecastedLoadFactor),
  headerName: t('forecasted_load_factor_short'),
  headerTooltip: t('forecasted_load_factor'),
  minWidth: 47,
  width: 47,
  sortable: true,
  type: ['numericColumn', 'number'],
  valueFormatter: (params: ValueFormatterParams) => {
    const value = params.value;

    if (isFinite(value)) {
      return value;
    }

    return undefined;
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin && matchingCabin.dataScienceRecommendation && matchingCabin.dataScienceRecommendation.forecastLoadFactor) {
      return round(matchingCabin.dataScienceRecommendation.forecastLoadFactor * 100, 1);
    }
  },
  menuTabs: ['filterMenuTab'],
  filter: 'agNumberColumnFilter',
  filterParams: { newRowsAction: 'keep' },
  requiredPermission(params) {
    return !!params.customerSettings.hasAirAsiaDataScienceIntegrationEnabled;
  },
});

export const generateCabinConfidenceScoreColumn = (cabinCode: string): ColDef => ({
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ConfidenceScore),
  headerName: t('confidence_score_short'),
  headerTooltip: t('confidence_score'),
  minWidth: 40,
  width: 40,
  sortable: true,
  type: 'numericColumn',
  valueFormatter: (params: ValueFormatterParams) => {
    const value = params.value;

    if (value && !isNaN(value)) {
      return value.toString();
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return (
      matchingCabin &&
      matchingCabin.dataScienceRecommendation &&
      matchingCabin.dataScienceRecommendation.modelConfidence &&
      matchingCabin.dataScienceRecommendation.modelConfidence.toFixed(2)
    );
  },
  menuTabs: ['filterMenuTab'],
  filter: 'agNumberColumnFilter',
  filterParams: { newRowsAction: 'keep' },
  requiredPermission(params) {
    return !!params.customerSettings.hasAirAsiaDataScienceIntegrationEnabled;
  },
});

export const generateCabinRecommendedFloorFareClassColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.RecommendedFloorFareClass),
  headerName: t('recommended_floor_fare_class_short'),
  headerTooltip: t('recommended_floor_fare_class'),
  minWidth: 35,
  width: 35,
  sortable: true,
  type: 'numericColumn',
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.dataScienceRecommendation && matchingCabin.dataScienceRecommendation.recommendedFloorFareClass;
  },
  menuTabs: ['filterMenuTab'],
  filterParams: { newRowsAction: 'keep' },
  requiredPermission(params) {
    return !!params.customerSettings.hasAirAsiaDataScienceIntegrationEnabled;
  },
});

export const generateCabinForecastedAverageFareColumn = (cabinCode: string): ColDef => ({
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.ForecastAverageFareAoc),
  headerName: t('forecased_average_fare_short'),
  headerTooltip: t('forecased_average_fare'),
  minWidth: 40,
  width: 40,
  sortable: true,
  type: 'numericColumn',
  valueFormatter: (params: ValueFormatterParams) => {
    const value = params.value;

    if (value && !isNaN(value)) {
      return FormatService.amountWithoutCurrency(value, params.data.fareCurrency);
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    return matchingCabin && matchingCabin.dataScienceRecommendation && matchingCabin.dataScienceRecommendation.forecastAverageFare;
  },
  menuTabs: ['filterMenuTab'],
  filter: 'agNumberColumnFilter',
  filterParams: { newRowsAction: 'keep' },
  requiredPermission(params) {
    return !!params.customerSettings.hasAirAsiaDataScienceIntegrationEnabled;
  },
});

export const generateCabinDataScienceCommentsColumn = (cabinCode: string): ColDef => ({
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.DataScienceCommentsColumn),
  headerName: t('comments'),
  headerTooltip: t('data_science_comments_description'),
  minWidth: 35,
  width: 35,
  sortable: true,
  comparator: StringOrNumberComparator,
  valueFormatter: (params: ValueFormatterParams) => {
    const value = params.value as string;

    if (value) {
      return value.replaceAll(':', ', ');
    }

    return '';
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);

    return matchingCabin && matchingCabin.dataScienceRecommendation && matchingCabin.dataScienceRecommendation.dataScienceLabels;
  },
  menuTabs: ['filterMenuTab'],
  filter: 'agTextColumnFilter',
  filterParams: { newRowsAction: 'keep' },
  requiredPermission(params) {
    return !!params.customerSettings.hasAirAsiaDataScienceIntegrationEnabled;
  },
});

export const generateDataScienceCabinColumns = (cabinCode: string, cabinClasses: ClassStructure[]): ColDef[] => [
  Columns.generateCabinRecommendedLowestAvailableFareDataScienceColumn(cabinCode, cabinClasses),
  Columns.generateCabinForecastedLoadFactorColumn(cabinCode),
  Columns.generateCabinConfidenceScoreColumn(cabinCode),
  Columns.generateCabinRecommendedFloorFareClassColumn(cabinCode),
  Columns.generateCabinForecastedAverageFareColumn(cabinCode),
  Columns.generateCabinDataScienceCommentsColumn(cabinCode),
];
