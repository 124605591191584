<template>
  <sticky-header>
    <template #title>
      <k-bread-crumb :crumbs="visibleBreadcrumbs" />
    </template>

    <template #menu>
      <el-button
        v-if="isOnLinkedClassRuleRoute"
        type="success"
        data-test="linked-class-rules-create-button"
        @click="navigateToCreateNewLinkedClassRule"
      >
        {{ $t('linked_class_rules.new_linked_class_rule') }}
      </el-button>

      <el-button v-if="isOnRivalRuleRoute" type="success" data-test="rival-rules-create-button" @click="navigateToCreateNewRivalRule">
        {{ $t('rival_rules.new_rival_rule') }}
      </el-button>
    </template>

    <template #content>
      <k-tabs class="mb-8">
        <k-tabs-router-link
          v-if="hasLinkedRulesEnabled"
          :route-options="{ name: RouteName.linkedClassRulesOverview }"
          data-test="tabs-overview-linked-class-rules"
        >
          {{ $t('linked_class_rules.linked_class') }}
        </k-tabs-router-link>

        <k-tabs-router-link
          v-if="hasRivalRulesEnabled"
          :route-options="{ name: RouteName.rivalRulesOverview }"
          data-test="tabs-overview-rival-rules"
        >
          {{ $t('rival_rules.rival_rules') }}
        </k-tabs-router-link>
      </k-tabs>

      <router-view />
    </template>
  </sticky-header>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { Router, useRoute, useRouter } from 'vue-router';

import { BreadCrumbInterface, RouteName } from '@/modules/shared';
import KBreadCrumb from '@/modules/shared/components/KBreadCrumb.vue';
import StickyHeader from '@/modules/shared/components/layouts/StickyHeader.vue';
import KTabs from '@/modules/shared/components/tabs/KTabs.vue';
import KTabsRouterLink from '@/modules/shared/components/tabs/KTabsRouterLink.vue';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const route = useRoute();
const router: Router = useRouter();
const { t } = useI18n();
const rivalRuleBreadcrumbs: BreadCrumbInterface[] = [
  {
    displayName: t('rules.rules'),
    to: { name: RouteName.rulesOverview },
  },
  {
    displayName: t('rival_rules.rival_rules'),
    to: { name: RouteName.rivalRulesOverview },
  },
];

const linkedClassBreadcrumbs: BreadCrumbInterface[] = [
  {
    displayName: t('rules.rules'),
    to: { name: RouteName.rulesOverview },
  },
  {
    displayName: t('linked_class_rules.linked_class'),
    to: { name: RouteName.linkedClassRulesOverview },
  },
];

const navigateToCreateNewLinkedClassRule = () => {
  router.push({ name: RouteName.linkedClassRulesCreate });
};

const navigateToCreateNewRivalRule = () => {
  router.push({ name: RouteName.rivalRulesCreate });
};

const hasLinkedRulesEnabled = computed(() => CustomerSettingsModule.settings.hasLinkedClassRulesEnabled);

const hasRivalRulesEnabled = computed(() => CustomerSettingsModule.settings.hasRivalRulesEnabled);

const isOnLinkedClassRuleRoute = computed(() => route.name === RouteName.linkedClassRulesOverview);

const isOnRivalRuleRoute = computed(() => route.name === RouteName.rivalRulesOverview);

const visibleBreadcrumbs = computed((): BreadCrumbInterface[] =>
  useRoute().name === RouteName.rivalRulesOverview ? rivalRuleBreadcrumbs : linkedClassBreadcrumbs,
);
</script>
