import { RouteAction, RouteActionPayload, RouteActionType } from './route-actions-contracts';

export interface SetOpLevelAdjustmentIncrementPayload extends RouteActionPayload {
  opLevelAdjustmentIncrement: number;
  cabinCode: string;
}

export const opLevelAdjustmentIncrementDefault: number | null = 50;

export class SetOpLevelAdjustmentIncrementAction implements RouteAction<SetOpLevelAdjustmentIncrementPayload> {
  public actionType: RouteActionType = RouteActionType.setOpLevelAdjustmentIncrement;

  public opLevelAdjustmentIncrement = opLevelAdjustmentIncrementDefault;

  public cabinCode = 'Y';

  constructor(cabinCode: string, opLevelAdjustmentIncrement: number) {
    this.cabinCode = cabinCode;
    this.opLevelAdjustmentIncrement = opLevelAdjustmentIncrement;
  }

  public setPayload(payload: SetOpLevelAdjustmentIncrementPayload): void {
    this.opLevelAdjustmentIncrement = payload.opLevelAdjustmentIncrement;
    this.cabinCode = payload.cabinCode;
  }

  public getPayload(): SetOpLevelAdjustmentIncrementPayload {
    return {
      actionType: this.actionType,
      cabinCode: this.cabinCode,
      opLevelAdjustmentIncrement: this.opLevelAdjustmentIncrement as number,
    };
  }
}
