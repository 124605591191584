import { RouteLocationRaw } from 'vue-router';

export interface BreadCrumbInterface {
  displayName: string;
  to: RouteLocationRaw;
}

export enum RouteName {
  clusterManagement = 'cluster-management',
  continuousPricing = 'continuous-pricing',
  control = 'control',
  dashboard = 'dashboard',
  dataImport = 'data-import',
  departed = 'departed',
  details = 'details',
  eventManagementOverview = 'event-management-overview',
  eventManagementInclusions = 'event-management-inclusions',
  eventManagementExclusions = 'event-management-exclusions',
  features = 'features',
  flows = 'flows',
  flowCreate = 'flow-create',
  flowsOverview = 'flows-overview',
  flowsArchived = 'flows-archived',
  flowsRoutines = 'flows-routines',
  flowView = 'flow-view',
  rulesOverview = 'rules-overview',
  linkedClassRulesOverview = 'linked-class-rules-overview',
  linkedClassRulesDetails = 'linked-class-rules-details',
  linkedClassRulesCreate = 'linked-class-rules-create',
  rivalRulesOverview = 'rival-rules-overview',
  rivalRulesDetails = 'rival-rules-details',
  rivalRulesCreate = 'rival-rules-create',
  login = 'login',
  pacingCurveCreate = 'pacing-curve-create',
  pacingCurveEdit = 'pacing-curve-edit',
  pacingCurveManagement = 'pacing-curve-management',
  promotionCreate = 'promotion-create',
  promotionEdit = 'promotion-edit',
  promotionManagement = 'promotion-management',
  reportingCreate = 'reporting-create',
  reportingEdit = 'reporting-edit',
  reportingListing = 'reporting-listing',
  review = 'review',
  routeManagement = 'route-management',
  system = 'system',
  customerConfig = 'customer-config',
  user = 'user',
  userChangePassword = 'user-change-password',
  userManagement = 'user-management',
  revenueComponentManagement = 'RevenueComponentsManagement',
  ancillaryCodesManagement = 'AncillaryCodesManagement',
  taxCodesManagement = 'TaxCodesManagement',
  surchargeCodesManagement = 'SurchargeCodesManagement',
}
