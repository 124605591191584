import { ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import {
  generateInventoryTacticsActiveOptimisationTacticsColumnsState,
  generateInventoryTacticsShadowOptimisationTacticsColumnsState,
  generateInventoryTacticsSharedFlightCabinColumnsState,
  getInventoryTacticsActiveOptimisationTacticsColumns,
  getInventoryTacticsShadowOptimisationTacticsColumns,
  getInventoryTacticsSharedFlightCabinColumns,
} from '@/models/columns/collections/inventory-tactics/shared';
import { ColumnGroupId } from '@/models/enums/grid';
import { PssName } from '@/modules/api/application/application-contracts';
import { i18n } from '@/plugins/i18n';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const { t } = i18n.global;

export const InventoryTacticColumns1d = (pss: PssName, performanceBandPickupDays: number[]): ColGroupDef[] => {
  const inventoryTacticsColumns = [
    {
      headerName: `${t('flight')} | ${t('cabin')}`,
      groupId: ColumnGroupId.CabinGroup,
      children: [...getInventoryTacticsSharedFlightCabinColumns(pss, performanceBandPickupDays)],
    },
    {
      headerName: `${t('active_optimisation_tactics')}`,
      groupId: ColumnGroupId.ActiveOptimisationTacticsGroup,
      children: [...getInventoryTacticsActiveOptimisationTacticsColumns()],
    },
  ];

  if (CustomerSettingsModule.settings.hasShadowTacticsEnabled) {
    inventoryTacticsColumns.push({
      headerName: t('shadow_tactics'),
      groupId: ColumnGroupId.ShadowOptimisationTacticsGroup,
      children: [...getInventoryTacticsShadowOptimisationTacticsColumns()],
    });
  }

  return inventoryTacticsColumns;
};

export const generateDefaultColumnState1d = (performanceBandPickupDays: number[]): ColumnState[] => {
  const columns = [
    ...generateInventoryTacticsSharedFlightCabinColumnsState(performanceBandPickupDays),
    ...generateInventoryTacticsActiveOptimisationTacticsColumnsState(),
  ];

  if (CustomerSettingsModule.settings.hasShadowTacticsEnabled) {
    columns.push(...generateInventoryTacticsShadowOptimisationTacticsColumnsState());
  }
  return columns;
};
