import { ElMessageBox } from 'element-plus';

import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;
const cancelButtonClass = 'data-test-cancel-button';

export class MessageBoxService {
  public static confirmLeaveForm() {
    return ElMessageBox.confirm(t('changes_will_not_be_saved'), t('leave_form'), {
      confirmButtonText: t('leave'),
      cancelButtonText: t('cancel'),
      type: 'warning',
      customClass: 'data-test-unsaved-changes',
      cancelButtonClass,
      confirmButtonClass: 'data-test-confirm-button',
    });
  }
  public static confirmChanges() {
    return ElMessageBox.confirm(t('confirm_changes'), t('warning'), {
      confirmButtonText: t('ok'),
      cancelButtonText: t('cancel'),
      type: 'warning',
      customClass: 'data-test-confirm-changes',
      cancelButtonClass,
      confirmButtonClass: 'data-test-confirm-button',
    });
  }
  public static confirmDelete() {
    return ElMessageBox.confirm(t('confirm_delete'), t('warning'), {
      confirmButtonText: t('ok'),
      cancelButtonText: t('cancel'),
      type: 'warning',
      customClass: 'data-test-confirm-delete',
      cancelButtonClass,
      confirmButtonClass: 'data-test-confirm-button',
    });
  }
  public static confirmCancelChanges() {
    return ElMessageBox.confirm(t('cancel_changes'), t('warning'), {
      confirmButtonText: t('ok'),
      cancelButtonText: t('cancel'),
      type: 'warning',
      customClass: 'data-test-confirm-cancel-changes',
      cancelButtonClass,
      confirmButtonClass: 'data-test-confirm-button',
    });
  }
  public static confirmBulkSaChange() {
    return ElMessageBox.confirm(t('bulk_sa_change'), t('warning'), {
      confirmButtonText: t('ok'),
      cancelButtonText: t('cancel'),
      type: 'warning',
      customClass: 'data-test-confirm-bulk-sa-change',
      cancelButtonClass,
      confirmButtonClass: 'data-test-confirm-button',
    });
  }
  public static unsavedChanges() {
    return ElMessageBox.confirm(t('unsaved_changes'), t('warning'), {
      confirmButtonText: t('leave_without_saving'),
      cancelButtonText: t('cancel'),
      type: 'warning',
      customClass: 'data-test-unsaved-changes',
      cancelButtonClass,
      confirmButtonClass: 'el-button--danger data-test-confirm-button',
    });
  }
  public static confirmSystemRestart() {
    return ElMessageBox.confirm(t('system_restart_warning'), t('warning'), {
      confirmButtonText: t('ok'),
      cancelButtonText: t('cancel'),
      type: 'warning',
      customClass: 'data-test-confirm-system-restart',
      cancelButtonClass,
      confirmButtonClass: 'el-button--danger data-test-confirm-button',
    });
  }
  public static confirmPromotionUpdatedChanges() {
    return ElMessageBox.confirm(t('promotion_update_confirm_changes'), t('warning'), {
      confirmButtonText: t('ok'),
      cancelButtonText: t('cancel'),
      type: 'warning',
      customClass: 'data-test-confirm-promotion-updated-changes',
      cancelButtonClass,
      confirmButtonClass: 'data-test-confirm-button',
    });
  }
}
