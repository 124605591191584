import { ApiClient } from '@/modules/api/base-client';

import { IRouteGroupsService, RouteGroupModel } from './route-groups-contracts';

export class RouteGroupsService implements IRouteGroupsService {
  private basePath = 'route-groups';

  public async getAll(): Promise<RouteGroupModel[]> {
    const response = await ApiClient.get<{
      content: RouteGroupModel[];
    }>(this.basePath);
    return response.content.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async post(routeGroup: RouteGroupModel): Promise<RouteGroupModel> {
    return await ApiClient.post<RouteGroupModel>(`${this.basePath}`, routeGroup);
  }
}

export const routeGroupsService = new RouteGroupsService();
