import { ColDef } from 'ag-grid-enterprise';

import { FlightViewFlightLineRow } from '@/models/FlightModel';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import {
  ancillaryRevenueCabinColumn,
  ancillaryRevenueClassColumn,
  ancillaryRevenueColumn,
  averageAncillaryRevenuePerPaxCabinColumn,
  averageAncillaryRevenuePerPaxClassColumn,
  averageAncillaryRevenuePerPaxColumn,
  surchargeRevenueCabinColumn,
  surchargeRevenueClassColumn,
  surchargeRevenueColumn,
  taxRevenueCabinColumn,
  taxRevenueClassColumn,
  taxRevenueColumn,
  totalRevenueCabinColumn,
  totalRevenueClassColumn,
  totalRevenueColumn,
} from '@/modules/revenues/columns';

export function generateAncillaryFlightColumns(): ColDef<FlightLineModel>[] {
  return [totalRevenueColumn, ancillaryRevenueColumn, surchargeRevenueColumn, taxRevenueColumn, averageAncillaryRevenuePerPaxColumn];
}

export function generateAncillaryCabinColumns(cabinCode: CabinCode): ColDef<FlightLineModel>[] {
  return [
    totalRevenueCabinColumn(cabinCode),
    ancillaryRevenueCabinColumn(cabinCode),
    surchargeRevenueCabinColumn(cabinCode),
    taxRevenueCabinColumn(cabinCode),
    averageAncillaryRevenuePerPaxCabinColumn(cabinCode),
  ];
}

export function generateAncillaryClassColumns(): ColDef<FlightViewFlightLineRow>[] {
  return [
    totalRevenueClassColumn,
    ancillaryRevenueClassColumn,
    surchargeRevenueClassColumn,
    taxRevenueClassColumn,
    averageAncillaryRevenuePerPaxClassColumn,
  ];
}
