export const en = {
  application: {
    menu_links: {
      cluster_management: 'Cluster Management',
      customer_config: 'Customer Config',
      data_import: 'External File Importer',
      event_management: 'Event Management',
      pacing_curve_management: 'Pacing Curve Management',
      promotion_management: 'Promotion Management',
      revenue_components_management: 'Revenue Components Management',
      rules: 'Rules',
      route_management: 'Route Management',
      system_settings: 'System settings',
      user_management: 'Users & Permissions',
    },
  },
};
