import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const PROMOTIONS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/promotions',
    name: RouteName.promotionManagement,
    meta: {
      authorities: [Authorities.PromotionRead],
    },
    component: () => import('@/modules/promotions/views/PromotionManagementListing.vue'),
  },
  {
    path: 'settings/promotions/:id/details',
    name: RouteName.promotionEdit,
    meta: {
      authorities: [Authorities.PromotionUpdate],
    },
    component: () => import('../views/PromotionManagementEditor.vue'),
  },
  {
    path: 'settings/promotions/create',
    name: RouteName.promotionCreate,
    meta: {
      authorities: [Authorities.PromotionUpdate],
    },
    component: () => import('../views/PromotionManagementEditor.vue'),
  },
];
