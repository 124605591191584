import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { FlightNumber } from '@/modules/api/flight/flight-contracts';
import { flightNumbersService } from '@/modules/api/flight/flight-numbers-service';
import { store } from '@/store';

// State definition
export interface IFlightNumbersState {
  flightNumbers: FlightNumber[];
}

@Module({ dynamic: true, store, name: 'flightNumbers', namespaced: true })
class FlightNumbers extends VuexModule implements IFlightNumbersState {
  // Default state
  public flightNumbers: FlightNumber[] = [];

  // Actions
  @Action
  public async getAll() {
    try {
      const response = await flightNumbersService.getAll();
      this.setFlightNumbers(response);
      return response;
    } catch (error) {
      console.warn('error fetching tags ', error);
    }
  }

  // Mutations
  @Mutation
  private setFlightNumbers(payload: FlightNumber[]) {
    this.flightNumbers = [...payload];
  }
}

export const FlightNumbersModule = getModule(FlightNumbers);
