import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import RivalRulesDetails from '@/modules/rules/rival-rules/views/RivalRulesDetails.vue';
import { RouteName } from '@/modules/shared/types/routes';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const hasRivalRulesEnabled = () => CustomerSettingsModule.settings.hasRivalRulesEnabled;

export const RIVAL_ROUTES: RouteRecordRaw[] = [
  {
    path: 'rival-rules/create',
    name: RouteName.rivalRulesCreate,
    meta: {
      authorities: [Authorities.RivalRulesUpdate],
      isAllowed: hasRivalRulesEnabled,
    },
    component: RivalRulesDetails,
  },
  {
    path: 'rival-rules/:id',
    name: RouteName.rivalRulesDetails,
    meta: {
      authorities: [Authorities.RivalRulesRead],
      isAllowed: hasRivalRulesEnabled,
    },
    component: RivalRulesDetails,
  },
];
