import { ApiClient } from '@/modules/api/base-client';

import { FlightNumber, IFlightnumbersService } from './flight-contracts';

export class FlightNumbersService implements IFlightnumbersService {
  private basePath = 'flightnumbers';

  public async getAll(): Promise<FlightNumber[]> {
    return await ApiClient.get<FlightNumber[]>(this.basePath);
  }
}

export const flightNumbersService = new FlightNumbersService();
