export enum ComponentName {
  OriginSelectFilterField = 'OriginSelectFilterField',
  DestinationSelectFilterField = 'DestinationSelectFilterField',
  TagSelectFilterField = 'TagSelectFilterField',
  AdjustmentRangeFilterField = 'AdjustmentRangeFilterField',
  AddSeatsLowestAvailableFareFilterField = 'AddSeatsLowestAvailableFareFilterField',
  AutopilotFilterField = 'AutopilotFilterField',
  CustomDateFilterField = 'CustomDateFilterField',
  CustomSelectFilterField = 'CustomSelectFilterField',
  CustomInventoryManagementField = 'CustomInventoryManagementField',
  CustomInventoryManagementPinField = 'CustomInventoryManagementPinField',
  CustomInventoryManagementDatePickerField = 'CustomInventoryManagementDatePickerField',
  CustomSeatAvailabilityManagementField = 'CustomSeatAvailabilityManagementField',
  CustomNumberInputFilterField = 'CustomNumberInputFilterField',
  CustomNumberRangeFilterField = 'CustomNumberRangeFilterField',
  DateRangeFilterField = 'DateRangeFilterField',
  DatePickerFilterField = 'DatePickerFilterField',
  FlightNumberRangeFilterField = 'FlightNumberRangeFilterField',
  FlightPathFilterField = 'FlightPathFilterField',

  HubFilterField = 'HubFilterField',
  MoveLafField = 'MoveLafField',
  MoveLafUpField = 'MoveLafUpField',
  MoveOptimizationProfileField = 'MoveOptimizationProfileField',
  MoveTacticalAdjustmentField = 'MoveTacticalAdjustmentField',
  OptimisationProfileField = 'OptimisationProfileField',
  SwapOptimisationProfileField = 'SwapOptimisationProfileField',
  OverbookingFilterField = 'OverbookingFilterField',
  ResolveLeftoverField = 'ResolveLeftoverField',
  TagsFilterField = 'TagsFilterField',
  TagsRemoveField = 'TagsRemoveField',
  ToggleFilterFieldType = 'ToggleFilterFieldType',
  UserFilterField = 'UserFilterField',
  OptimizationProfileFilterField = 'OptimizationProfileFilterField',
  OptimizationTacticFilterField = 'OptimizationTacticFilterField',
  ClusterFilterField = 'ClusterFilterField',
  RouteGroupFilterField = 'RouteGroupFilterField',
  CarrierCodeFilterField = 'CarrierCodeFilterField',
  AircraftFilterField = 'AircraftFilterField',
  DayOfWeekFilterField = 'DayOfWeekFilterField',
  DistributionOptionFilterField = 'DistributionOptionFilterField',
  CustomAuthorizationUnitsManagementField = 'CustomAuthorizationUnitsManagementField',
  TacticAdjustmentSlider = 'TacticAdjustmentSlider',
  PricingAggregationSelect = 'PricingAggregationSelect',
  PricingTacticSelect = 'PricingTacticSelect',
  UpdateClusterLevelField = 'UpdateClusterLevelField',
  MoveClusterLevelField = 'MoveClusterLevelField',
  SetCabinPromotionActionEditor = 'SetCabinPromotionActionEditor',
  LinkedClassRuleSelectFilterField = 'LinkedClassRuleSelectFilterField',
  RivalRuleSelectFilterField = 'RivalRuleSelectFilterField',
  MatchCompetitorField = 'MatchCompetitorField',
  SetRecommendedOverbookingRiskPercentageFilterField = 'SetRecommendedOverbookingRiskPercentageFilterField',
  MoveRecommendedOverbookingRiskPercentageFilterField = 'MoveRecommendedOverbookingRiskPercentageFilterField',
  OverbookingAutopilotField = 'OverbookingAutopilotField',
}
