import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { ICreateTag, ITag, tagsService } from '@/modules/tags';
import { i18n } from '@/plugins/i18n';
import { store } from '@/store';

export interface ITagsState {
  tags: ITag[];
  get: () => Promise<ITag[]>;
  create: (tags: ICreateTag[]) => Promise<ITag[]>;
}

@Module({ dynamic: true, store, name: 'tags', namespaced: true })
class Tags extends VuexModule implements ITagsState {
  private _collator = new Intl.Collator(i18n.global.locale.value);

  public unsortedTags: ITag[] = [];

  get tags(): ITag[] {
    return [...this.unsortedTags].sort(({ name: nameA }, { name: nameB }) => this._collator.compare(nameA, nameB));
  }

  @Action
  public async get() {
    try {
      const response = await tagsService.get();
      this.setTags(response);
      return response;
    } catch (error) {
      console.warn('error fetching tags ', error);
    }
  }

  @Action
  public async create(tags: ICreateTag[]) {
    const response = await tagsService.post(tags);
    const allTags = [...this.unsortedTags, ...response];
    this.setTags(allTags);
    return response;
  }

  @Mutation
  public setTags(payload: ITag[]) {
    this.unsortedTags = [...payload];
  }
}

export const TagsModule = getModule(Tags);
