/* eslint-disable import/order */

// Packages
import { createApp } from 'vue';

// Plugins
import { router } from '@/modules/router';
import { AgGrid } from '@/plugins/ag-grid';
import { Authority } from '@/plugins/authority';
import { Firebase } from '@/plugins/firebase';
import { FontAwesome } from '@/plugins/font-awesome';
import { EddyGridLayout } from '@/plugins/grid-layout';
import { i18n } from '@/plugins/i18n';
import { store } from '@/store';
import ElementPlus, { dayjs } from 'element-plus';
import VCalendar from 'v-calendar';
import 'v-calendar/dist/style.css';
import { plugin as VueInputAutoWidth } from 'vue-input-autowidth';
import VueApexCharts from 'vue3-apexcharts';
import { createPinia } from 'pinia';

const pinia = createPinia();

/**
 * Environment
 */
import { Environment } from '@/environment';

/**
 * Module Bootstrap
 */
import { initApiModule } from '@/modules/api';
import { initSharedModule } from '@/modules/shared';

initSharedModule();
initApiModule();

/**
 * Make sure weeks start on Monday!
 */
import en from 'dayjs/locale/en';
dayjs.locale({ ...en, weekStart: 1 });

/**
 * Application Initialization
 */
import App from '@/App.vue';

const app = createApp(App)
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(store)
  .use(Firebase)
  .use(AgGrid)
  .use(FontAwesome)
  .use(EddyGridLayout)
  .use(ElementPlus, { size: 'small', zIndex: 3000 })
  .use(VueApexCharts)
  .use(VueInputAutoWidth)
  .use(VCalendar)
  .use(Authority);

/**
 * Assign a handler for uncaught errors during component render function and watchers.
 * The handler gets called with the error and the Vue instance.
 * See: https://v2.vuejs.org/v2/api/#errorHandler
 */
import { logger } from '@/modules/monitoring';

app.config.errorHandler = (err: unknown, vm: any, info: string) => {
  logger.error(err as Error, { info });
};

/**
 * Enable Performance Monitoring in Development
 */
app.config.performance = Environment.isDevelopment;

/**
 * Attach our App to the DOM
 */
app.mount('#app');
