export enum RouteActionType {
  setCompetitiveFareRange = 'set_competitive_fare_range',
  setDirection = 'set_direction',
  setFareCurrency = 'set_fare_currency',
  setOpLevelAdjustmentIncrement = 'set_op_level_adjustment_increment',
  setForecastGeneration = 'set_forecast_generation',
  setForecastLearning = 'set_forecast_learning',
}

export interface RouteActionPayload {
  actionType: RouteActionType;
}

export interface RouteAction<T> {
  actionType: RouteActionType;
  /**
   * getPayload gets the payload form the action,
   * which is a DTO ready to be sent to the API,
   * containing the data needed to execute the action
   */
  getPayload: () => T;
  setPayload: (payload: T) => void;
  value?: any;
}
