import { FilterField, FilterGroup } from '@/modules/api/shared-contracts';

export enum AggregationType {
  Sum = 'sum',
  Average = 'avg',
  Count = 'count',
}

export enum PickupType {
  booking = 'booking',
  revenue = 'revenue',
  performanceBand = 'performanceBand',
}

export enum NdoType {
  booking = 'booking',
  revenue = 'revenue',
}

export enum InventoryManagementMethodology {
  au = 'AU',
  pr = 'PR',
  auAndPr = 'AUPR',
}
export interface NumberOfFlightsResponse {
  count: number;
  filters?: FilterGroup | FilterField;
}

export enum InventoryManagementType {
  Leg = 'LEG',
  Segment = 'SEGMENT',
  Network = 'NETWORK',
}

export enum ScheduleMode {
  SEGMENT = 'segment',
  NETWORK = 'network',
}

export enum Hemisphere {
  NORTHERN = 'northern',
  SOUTHERN = 'southern',
}

export const OverbookingRiskValues: { [key: string]: number } = {
  OVERBOOKING_RISK_0: 0,
  OVERBOOKING_RISK_1: 1,
  OVERBOOKING_RISK_3: 3,
  OVERBOOKING_RISK_5: 5,
  OVERBOOKING_RISK_10: 10,
  OVERBOOKING_RISK_15: 15,
  OVERBOOKING_RISK_20: 20,
  OVERBOOKING_RISK_25: 25,
  OVERBOOKING_RISK_30: 30,
  OVERBOOKING_RISK_35: 35,
  OVERBOOKING_RISK_40: 40,
  OVERBOOKING_RISK_45: 45,
  OVERBOOKING_RISK_50: 50,
  OVERBOOKING_RISK_55: 55,
  OVERBOOKING_RISK_60: 60,
  OVERBOOKING_RISK_65: 65,
  OVERBOOKING_RISK_70: 70,
  OVERBOOKING_RISK_75: 75,
  OVERBOOKING_RISK_80: 80,
  OVERBOOKING_RISK_85: 85,
  OVERBOOKING_RISK_90: 90,
  OVERBOOKING_RISK_95: 95,
  OVERBOOKING_RISK_97: 97,
  OVERBOOKING_RISK_99: 99,
};
