import { RowNode } from 'ag-grid-enterprise';
import { isEmpty } from 'lodash-es';
import moment from 'moment';

import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { PromotionStateUI } from '@/modules/promotions/api/promotion-contracts';
import { ComparatorResult } from '@/modules/shared/types/comparator-result';
import { DateTimeService } from '@/services/date-time.service';
import { FlightService } from '@/services/flight.service';

export const DateComparator = (
  filterInput: Date,
  nodeValue: string,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean,
): ComparatorResult => {
  if (!filterInput) {
    return isInverted ? -1 : 1;
  }

  if (!nodeValue) {
    return isInverted ? 1 : -1;
  }

  const filterDate = moment(filterInput).format('YYYY-MM-DD');
  const formattedNodeValue = moment(nodeValue).format('YYYY-MM-DD');

  return moment(filterDate).isBefore(formattedNodeValue) ? -1 : moment(formattedNodeValue).isBefore(moment(filterDate)) ? 1 : 0;
};

export const TimeComparator = (valueA: string, valueB: string, nodeA: RowNode, nodeB: RowNode, isInverted: boolean): ComparatorResult => {
  if (valueA === undefined && valueB === undefined) {
    return 0;
  }
  if (valueA === undefined) {
    return isInverted ? -1 : 1;
  }
  if (valueB === undefined) {
    return isInverted ? 1 : -1;
  }

  if (!nodeA || !nodeB) {
    if (valueA === valueB) {
      return 0;
    } else {
      return isEmpty(valueA) ? 1 : -1;
    }
  }
  const hoursAndMinutesA = (nodeA.data as FlightLineModel).departureTime.split(':');
  const totalMinutesA = Number(hoursAndMinutesA[0]) * 60 + Number(hoursAndMinutesA[1]);
  const hoursAndMinutesB = (nodeB.data as FlightLineModel).departureTime.split(':');
  const totalMinutesB = Number(hoursAndMinutesB[0]) * 60 + Number(hoursAndMinutesB[1]);

  return totalMinutesA === totalMinutesB ? 0 : totalMinutesA < totalMinutesB ? -1 : 1;
};

export const DateTimeInvertedComparator = (valA: moment.MomentInput | null, valB: moment.MomentInput | null): ComparatorResult => {
  if (!valA && !valB) {
    return 0;
  }
  if (!valA) {
    return -1;
  }
  if (!valB) {
    return 1;
  }

  const momentA: moment.Moment = moment(valA),
    momentB: moment.Moment = moment(valB);

  return momentA.isBefore(momentB) ? 1 : momentB.isBefore(momentA) ? -1 : 0;
};

export const DateTimeComparator = (valA: moment.MomentInput | null, valB: moment.MomentInput | null): ComparatorResult => {
  if (!valA && !valB) {
    return 0;
  }
  if (!valA) {
    return -1;
  }
  if (!valB) {
    return 1;
  }

  const momentA: moment.Moment = moment(valA),
    momentB: moment.Moment = moment(valB);

  return momentA.isAfter(momentB) ? 1 : momentB.isAfter(momentA) ? -1 : 0;
};

export const StringOrNumberComparator = (
  valueA: string | number | undefined | null,
  valueB: string | number | undefined | null,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean,
) => {
  const definedValueA: boolean = valueA !== undefined && valueA !== null;
  const definedValueB: boolean = valueB !== undefined && valueB !== null;
  if (!definedValueA && !definedValueB) {
    return 0;
  }
  // Always place the undefined and null values at the bottom, regardless of the sort order
  if (!definedValueA) {
    return isInverted ? -1 : 1;
  }
  if (!definedValueB) {
    return isInverted ? 1 : -1;
  }

  if (typeof valueA === 'string' && typeof valueB === 'string') {
    return valueA.toString().toLowerCase().localeCompare(valueB.toString().toLowerCase());
  } else {
    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
  }
};

export const DayOfWeekComparator = (
  valueA: string,
  valueB: string,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean,
): ComparatorResult => {
  if (valueA === undefined && valueB === undefined) {
    return 0;
  }
  if (valueA === undefined) {
    return isInverted ? -1 : 1;
  }
  if (valueB === undefined) {
    return isInverted ? 1 : -1;
  }

  if (!nodeA || !nodeB) {
    if (valueA === valueB) {
      return 0;
    } else {
      return DateTimeService.defaultWeekdays().indexOf(valueA) < DateTimeService.defaultWeekdays().indexOf(valueB) ? 1 : -1;
    }
  }
  const dateA = DateTimeService.getDate({
    date: (nodeA.data as FlightLineModel).firstLegDepartureDate,
  });
  const dateB = DateTimeService.getDate({
    date: (nodeB.data as FlightLineModel).firstLegDepartureDate,
  });

  return dateA.isoWeekday() === dateB.isoWeekday() ? 0 : dateA.isoWeekday() < dateB.isoWeekday() ? -1 : 1;
};

export const PromotionStateComparator = (
  valueA: PromotionStateUI,
  valueB: PromotionStateUI,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean,
) => {
  if (valueA === undefined && valueB === undefined) {
    return 0;
  }
  if (valueA === undefined) {
    return isInverted ? -1 : 1;
  }
  if (valueB === undefined) {
    return isInverted ? 1 : -1;
  }
  return valueB.value.localeCompare(valueA.value);
};

export const RecommendedLowestAvailableFareComparator = (
  valueA: string,
  valueB: string,
  nodeA: RowNode,
  nodeB: RowNode,
  isInverted: boolean,
  classes: string[],
  cabinCode: string,
): ComparatorResult => {
  // First sort out the values where there is no LAF
  if (valueA === undefined && valueB === undefined) {
    return 0;
  }

  if (isEmpty(valueA)) {
    return isInverted ? -1 : 1;
  }
  if (isEmpty(valueB)) {
    return isInverted ? 1 : -1;
  }

  // If the LAF's are the same, then order on SeatAvailability
  if (classes.indexOf(valueA) === classes.indexOf(valueB)) {
    const matchingCabinA = FlightService.getMatchedCabin(nodeA.data, cabinCode);
    let lafSeatAvailabilityA = undefined;
    if (matchingCabinA.dataScienceRecommendation) {
      lafSeatAvailabilityA = matchingCabinA.dataScienceRecommendation.recommendedSeatsAvailable;
    }

    const matchingCabinB = FlightService.getMatchedCabin(nodeB.data, cabinCode);

    let lafSeatAvailabilityB = undefined;
    if (matchingCabinB.dataScienceRecommendation) {
      lafSeatAvailabilityB = matchingCabinB.dataScienceRecommendation.recommendedSeatsAvailable;
    }

    return lafSeatAvailabilityA < lafSeatAvailabilityB ? -1 : 1;
  }
  // If the LAFs are not the same, order them according to the class structure
  else {
    return classes.indexOf(valueA) < classes.indexOf(valueB) ? 1 : -1;
  }
};

export function sortAlphabetically(a: number | string, b: number | string, collator: InstanceType<typeof Intl.Collator>): number {
  const numberA = parseInt(`${a}`);
  const numberB = parseInt(`${b}`);

  if (!isNaN(numberA) && !isNaN(numberB)) {
    return numberA - numberB;
  } else if (!isNaN(numberA)) {
    return 1;
  } else if (!isNaN(numberB)) {
    return -1;
  } else {
    return collator.compare(`${a}`, `${b}`);
  }
}
