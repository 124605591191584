import { ApiClient, IntegrationClient } from '@/modules/api/base-client';

import { ISystemService, SystemConfigModel } from './system.contracts';

export class SystemService implements ISystemService {
  private basePath = 'system';

  public getConfig(): Promise<SystemConfigModel> {
    return ApiClient.get<SystemConfigModel>(`${this.basePath}/settings`);
  }

  public async restartSystem(): Promise<void> {
    return IntegrationClient.post<void>('selfDestruct', {});
  }
}

/**
 * SystemService is a service that calls the '/system' part of the api.
 */
export const systemService = new SystemService();
