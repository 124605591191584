import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const ROUTE_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/route-management',
    name: RouteName.routeManagement,
    meta: {
      authorities: [Authorities.RouteRead],
    },
    component: () => import('../views/RouteManagementView.vue'),
  },
];
