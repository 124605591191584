import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const USER_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/user-management',
    name: RouteName.userManagement,
    meta: {
      authorities: [Authorities.UserRead],
    },
    component: () => import('@/modules/user-management/views/UserManagement.vue'),
  },
];
