import { CascaderItem } from '@/models/element-ui';
import { InventoryConfigurationProperties } from '@/modules/api/application/application-contracts';
import { ApiClient } from '@/modules/api/base-client';
import { CustomerSettingsModel } from '@/modules/api/customer-settings/customer-settings-contracts';
import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { FlightActionConfigurationParams, FlightActionFactory } from '@/modules/flight-actions/actions/definitions/flight-action-factory';
import {
  BaseFlightActionPayload,
  FlightAction,
  FlightActionType,
  FlightCollectionAction,
  IFlightActionService,
} from '@/modules/flight-actions/api/flight-actions-contracts';
import { FilterFieldDefinition, FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';
import { GuidService } from '@/modules/shared/services/guid.service';

// TODO: assign executors to FlightAction so we can remove this switch case, see: flight-actions->actions folder for executor classes
export function generateFlightCollectionAction(
  flightActions: FlightActionDefinition[],
  flightLines: FlightLineModel[],
  config: FlightActionConfigurationParams,
): FlightCollectionAction {
  const flightCollectionAction: FlightCollectionAction = {
    ondIds: flightLines.map((flightLine: FlightLineModel) => flightLine.id),
    actions: [],
  };

  flightCollectionAction.actions = generateFlightActions(flightActions, { ...config, flightLines });
  return flightCollectionAction;
}

export function generateFlightActions(
  flightActions: FlightActionDefinition[],
  config: FlightActionConfigurationParams,
): FlightAction<any>[] {
  const actions: FlightAction<any>[] = [];
  for (const action of flightActions) {
    const generatedAction = FlightActionFactory.getAction({
      type: action.flightActionType,
      config: {
        inventoryConfigurationProperties: config.inventoryConfigurationProperties,
        pssCapabilities: config.pssCapabilities,
        priceIncrementRange: config.priceIncrementRange,
        flightLines: config.flightLines ?? [],
      },
    });
    generatedAction.setValueByDefinition(action);
    actions.push(generatedAction.getPayload(config.flightLines));
  }
  return actions;
}

export class FlightActionService implements IFlightActionService {
  static filterOutActionInCascader(
    actions: CascaderItem<FlightActionDefinition>[],
    actionType: FlightActionType,
  ): CascaderItem<FlightActionDefinition>[] {
    // Recursively try to find the action we want to filter out
    return actions
      .map((action) => {
        if (action.children) {
          action.children = this.filterOutActionInCascader(action.children, actionType);
        } else if (action.meta.flightActionType === actionType) {
          // Return undefined which we need to filter out afterwards
          return;
        }

        return action;
      })
      .filter((action) => action);
  }

  public static transformToFilterFieldDefinitions(
    flightActions: FlightAction<any>[],
    customerSettings: CustomerSettingsModel<UiAndApiPssCapabilities>,
    inventoryConfigurationProperties: InventoryConfigurationProperties,
    flightLines: FlightLineModel[] = [],
  ): FilterFieldDefinition[] {
    const filterFieldDefinitions: FilterFieldDefinition[] = [];

    for (const action of flightActions) {
      let filterFieldToAdd: FilterFieldDefinition;

      if (Object.values(FlightActionType).includes(action.actionType)) {
        const generatedAction = FlightActionFactory.getAction({
          type: action.actionType,
          payload: action,
          config: {
            inventoryConfigurationProperties: inventoryConfigurationProperties,
            pssCapabilities: customerSettings.pssCapabilities,
            priceIncrementRange: customerSettings.priceAdjustmentRange,
            flightLines,
          },
        });

        filterFieldToAdd = generatedAction.cascaderOption.meta;
      } else {
        console.warn(`[Method "transformToFilterFieldDefinitions"] Type not found to transform: ${action}`, action);
      }

      filterFieldToAdd.uid = GuidService.newGuid();
      filterFieldDefinitions.push(filterFieldToAdd);
    }

    return filterFieldDefinitions;
  }

  private basePath = 'onds';

  public async applyActions(
    flightActions: FlightActionDefinition[],
    flightLines: FlightLineModel[],
    config: FlightActionConfigurationParams,
  ): Promise<any> {
    return await this.patchCollection(generateFlightCollectionAction(flightActions, flightLines, config));
  }

  public async applyActionsById(
    flightActions: BaseFlightActionPayload[],
    flightLine: FlightLineModel,
    preview: boolean,
  ): Promise<FlightLineModel> {
    return this.patchCollectionById(flightLine.id, { actions: flightActions, ondIds: [flightLine.id] }, preview);
  }

  public async patchCollection(request: FlightCollectionAction): Promise<any> {
    return ApiClient.patch<any[]>(`/${this.basePath}`, request);
  }

  public async patchCollectionById(id: number, request: FlightCollectionAction, preview = true): Promise<any> {
    return ApiClient.patch<any[]>(`/${this.basePath}/${id}/actions?preview=${preview}`, request);
  }
}

export const flightActionService = new FlightActionService();
