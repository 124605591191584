// User config
import { ColumnState } from 'ag-grid-enterprise';

import { FlightFieldName } from '@/models/enums/flight/flight-field-name';
import { Authorities } from '@/modules/api/auth/auth-contracts';
import { FilterFieldField, RouteFilterType } from '@/modules/api/shared-contracts';
import { CrossFilterType } from '@/modules/control/models/crossfilter/crossfilter-configuration';
import { InventoryManagementMethodology } from '@/modules/shared/shared-contracts';
import { UserModel } from '@/modules/user-settings/api/users/users-management.contracts';
import { ExportSeparator } from '@/modules/user-settings/types';

export type DatePattern = 'YYYY/MM/DD' | 'DD-MM-YYYY' | 'DD/MM/YYYY' | 'DD.MM.YYYY' | 'MM/DD/YYYY';

export type Language =
  | 'en-US'
  | 'zh-CN'
  | 'ru-RU'
  | 'fr-FR'
  | 'es-ES'
  | 'en-GB'
  | 'de-DE'
  | 'pt-BR'
  | 'en-CA'
  | 'es-MX'
  | 'it-IT'
  | 'ja-JP';

export enum LafLoadFactorColoring {
  OFF = 'OFF',
  RED_TO_GREEN = 'RED_TO_GREEN',
}

export interface UserConfigModel {
  fontSize: number;
  margin: number;
  datePattern: DatePattern | null;
  language: Language;
  inventoryGridColumnState: ColumnState[];
  departedGridColumnState: ColumnState[];
  controlGridColumnState: ColumnState[];
  cabinInventoryGridColumnState: ColumnState[];
  detailsViewTableOrder: boolean;
  departedViewTableOrder: boolean;
  authorities: Authorities[];
  inventoryManagementMethodology: InventoryManagementMethodology;
  controlSettings: ControlSettings;
  controlBookingsPickUpPoints: number[];
  controlPerformanceBandPickUpPoints: number[];
  detailsBookingsPickUpPoints: number[];
  detailsPerformanceBandPickUpPoints: number[];
  lafColoring: LafLoadFactorColoring;
  displaySeatAvailabilityAsZero: boolean;
  exportSeparator: ExportSeparator;
}

export type UserConfigModelDTO = Partial<UserConfigModel>;

export type GridColumnStates = Partial<
  Pick<UserConfigModel, 'controlGridColumnState' | 'cabinInventoryGridColumnState' | 'inventoryGridColumnState'>
>;

export interface ControlSettings {
  filters?: FilterFieldField[];
  /**
   * This is the actual state of the crossfilter so the UI can restore it on a new session.
   * List of unique crossfilter id's.
   */
  crossfilterState?: CrossfilterUserConfig[];
  competitors?: string[];
  groupKey?: FlightFieldName[];
  routeFilterType?: RouteFilterType;
}

export type CrossfilterUserConfig = {
  widget: CrossFilterType;
  cabinCode?: string;
};

export interface IUserService {
  getByEmail(email: string): Promise<UserModel>;
  patchConfig(id: number, config: UserConfigModelDTO): Promise<UserConfigModelDTO>;
  changePassword(currentPassword: string, newPassword: string): Promise<void>;
}
