import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { calendarService } from '@/modules/shared/api/calendar/calendar-service';
import { store } from '@/store';

import { CalendarEvent, CalendarEventType } from '../../api/calendar/calendar-service-contracts';

export interface ICalendarState {
  seasons: CalendarEvent[];
}

@Module({ dynamic: true, store, name: 'calendar', namespaced: true })
class Calendar extends VuexModule implements ICalendarState {
  public seasons: CalendarEvent[] = [];

  @Action
  public async getSeasonsAction(captureDate: string) {
    return await calendarService.getBy(CalendarEventType.Season, captureDate);
  }

  @Action
  public async setSeasonsAction(captureDate: string) {
    const seasons = await this.getSeasonsAction(captureDate);
    this.setSeasons(seasons);
  }

  @Mutation
  public setSeasons(seasons: CalendarEvent[]) {
    this.seasons = seasons;
  }
}

export const CalendarModule = getModule(Calendar);
