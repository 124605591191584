import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { RouteGroupModel } from '@/modules/route-management/api/route-groups/route-groups-contracts';
import { routeGroupsService } from '@/modules/route-management/api/route-groups/route-groups-service';
import { store } from '@/store';

// State definition
export interface IRouteGroupsState {
  allRouteGroups: RouteGroupModel[];
}

@Module({ dynamic: true, store, name: 'routeGroups', namespaced: true })
class RouteGroups extends VuexModule implements IRouteGroupsState {
  // Default state
  public allRouteGroups: RouteGroupModel[] = [];

  // Actions
  @Action
  public async getAll() {
    try {
      const response = await routeGroupsService.getAll();
      this.setAll(response);
      return response;
    } catch (error) {
      console.warn('error fetching tags ', error);
    }
  }

  // Mutations
  @Mutation
  private setAll(payload: RouteGroupModel[]) {
    this.allRouteGroups = [...payload];
  }
}

export const RouteGroupsModule = getModule(RouteGroups);
