import { ApiClient } from '@/modules/api/base-client';
import { ClusterAssignmentPayload, DataImportAssignment } from '@/modules/data-import/api/data-import/data-import-contracts';

import { ClusterModel, IClusterService } from './cluster-contracts';

export class ClusterService implements IClusterService {
  private basePath = 'clusters';

  public async getAll(): Promise<ClusterModel[]> {
    const clusters = await ApiClient.get<ClusterModel[]>(`${this.basePath}`);
    return clusters.sort((a, b) => a.name.localeCompare(b.name));
  }

  public async getById(clusterId: number): Promise<ClusterModel> {
    return await ApiClient.get<ClusterModel>(`${this.basePath}/${clusterId}`);
  }

  public async create(cluster: ClusterModel): Promise<ClusterModel> {
    return await ApiClient.post<ClusterModel>(`${this.basePath}`, cluster);
  }

  public async update(id: number, cluster: ClusterModel): Promise<ClusterModel> {
    return await ApiClient.put<ClusterModel>(`${this.basePath}/${id}`, cluster);
  }

  public async bulkAssign(request: DataImportAssignment<ClusterAssignmentPayload>): Promise<void> {
    return await ApiClient.patch<void>(`${this.basePath}/bulk-assign`, request);
  }

  public async copy(cluster: ClusterModel, clustersListLength: number): Promise<void> {
    const copiedCluster = {
      ...cluster,
      // TODO: figure out how to handle copy of clusters with max 5 chars and adding cluster length to name
      name: cluster.name.substr(0, 4) + clustersListLength,
    };

    await clusterService.create(copiedCluster);
  }
}

export const clusterService = new ClusterService();
