import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const DATA_IMPORT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'settings/data-import',
    name: RouteName.dataImport,
    meta: {
      authorities: [Authorities.DataImportUpdate],
    },
    component: () => import('../views/DataImportView.vue'),
  },
];
