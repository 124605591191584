<template>
  <div>
    <router-view />
  </div>
</template>

<script lang="ts">
import { isEmpty } from 'lodash-es';
import { defineComponent } from 'vue';

import { Constants } from '@/constants';
import { PssName } from '@/modules/api/application/application-contracts';
import { Authorities } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import { RouteName } from '@/modules/shared';
import { FlightService } from '@/services/flight.service';
import { AppSettingsModule } from '@/store/modules/app-settings.module';
import { AuthModule } from '@/store/modules/auth.module';
import { FlightModule } from '@/store/modules/flight.module';
import { UserConfigModule } from '@/store/modules/user-config.module';

export default defineComponent({
  name: 'App',
  computed: {
    username(): string {
      return AuthModule.username;
    },
    fontSize(): string {
      return `${UserConfigModule?.fontSize ?? Constants.DEFAULT_FONT_SIZE}px`;
    },
  },
  async created() {
    // TODO: This shouldn't be on app level,
    // It should move to the page that is blocking navigation
    window.addEventListener('beforeunload', (event) => {
      if (FlightService.hasUnsavedChanges(FlightModule.flightActions)) {
        event.preventDefault();
        event.returnValue = '';
      }
    });

    if (AuthModule.token) {
      if (this.$router.currentRoute.value.name === RouteName.login && isEmpty(this.$router.currentRoute.value.query.redirect)) {
        // If you are a Navitaire customer and you load the app, and you request the initial page (either "/" or "/app/dashboard")
        // replace the dashboard route with the control route, since that should be the (temporary) default
        // to avoid long-loading dashboards and resulting load on the database
        const flightReadAuthority = authService.hasAuthority([Authorities.FlightRead], UserConfigModule.user.authorities);
        this.$router.push(
          this.$router.currentRoute.value.query.redirect || AppSettingsModule.inventoryConfigurationProperties.pss === PssName.Navitaire
            ? flightReadAuthority
              ? { name: RouteName.control }
              : { name: RouteName.user }
            : flightReadAuthority
            ? { name: RouteName.dashboard }
            : { name: RouteName.user },
        );
      }
    } else {
      if (!window.location.pathname.startsWith('/external')) {
        this.$router.push({
          name: RouteName.login,
          query: {
            redirect: window.location.pathname + window.location.search,
          },
        });
      }
    }
  },
});
</script>

<style lang="scss">
// Import all application wide styles
@import '@/styles/index.scss';

// Override the font size for all grids
:root,
.ag-theme-alpine,
.ag-theme-alpine-dark,
.ag-theme-klimb,
.ag-theme-klimb-compact {
  --ag-font-size: v-bind(fontSize);
}
</style>
