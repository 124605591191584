import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { InventoryManagementSettings, LegConfig, NetworkConfig, SegmentConfig } from '@/models/InventoryManagement';
import { InventoryManagementType } from '@/modules/shared/shared-contracts';
import { SystemConfigModel } from '@/modules/system-settings/api/system/system.contracts';
import { systemService } from '@/modules/system-settings/api/system/system.service';
import { store } from '@/store';

// State definition
export interface IConfigurationState {
  flightViewConfig: InventoryManagementSettings;
  inventoryManagementType: InventoryManagementType;
  overbooking: boolean;
  captureDate: string;
  carrierCodes: string[];
  cfCaptureDate: string;
  dataScienceCaptureDate?: string;
}

@Module({ dynamic: true, store, name: 'configuration', namespaced: true })
class Configuration extends VuexModule implements IConfigurationState {
  // Default state
  public flightViewConfig: InventoryManagementSettings = NetworkConfig;
  public inventoryManagementType: InventoryManagementType = InventoryManagementType.Network;
  public overbooking = false;
  public captureDate = '';
  public cfCaptureDate = '';
  public dataScienceCaptureDate = '';
  public carrierCodes: string[] = [];

  // Actions
  @Action
  public async getConfiguration() {
    try {
      const response = await systemService.getConfig();
      let flightViewConfig;
      switch (response.inventoryManagementType) {
        case InventoryManagementType.Leg:
          flightViewConfig = LegConfig;
          break;
        case InventoryManagementType.Network:
          flightViewConfig = NetworkConfig;
          break;
        case InventoryManagementType.Segment:
          flightViewConfig = SegmentConfig;
          break;
      }
      this.setFlightViewConfig(flightViewConfig);
      this.setConfig(response);
      return response;
    } catch (error) {
      return null;
    }
  }

  // Mutations
  @Mutation
  public setFlightViewConfig(flightViewConfig: InventoryManagementSettings) {
    this.flightViewConfig = flightViewConfig;
  }

  @Mutation
  public setConfig(config: SystemConfigModel) {
    this.inventoryManagementType = config.inventoryManagementType;
    this.overbooking = config.overbooking;
    this.captureDate = config.captureDate;
    this.cfCaptureDate = config.cfCaptureDate;
    this.dataScienceCaptureDate = config.dataScienceCaptureDate;

    this.carrierCodes = config.carrierCodes.sort();
  }

  get getCaptureDate() {
    return this.captureDate;
  }
}

export const ConfigurationModule = getModule(Configuration);
