import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const CONTROL_ROUTES: RouteRecordRaw[] = [
  {
    path: 'flights',
    redirect: { name: RouteName.control },
    children: [
      {
        path: 'control',
        name: RouteName.control,
        meta: {
          authorities: [Authorities.FlightRead],
        },
        component: () => import('@/modules/control/views/FlightControl.vue'),
      },
      {
        path: 'control/review',
        name: RouteName.review,
        meta: {
          authorities: [Authorities.FlightRead],
        },
        component: () => import('@/modules/control/views/FlightReview.vue'),
      },
      // Redirect in place so user's bookmarks still work
      {
        path: 'details',
        redirect: { name: RouteName.details },
      },
    ],
  },
];
