import { Authorities } from '@/modules/api/auth/auth-contracts';
import LinkedClassRulesDetails from '@/modules/rules/linked-class-rules/views/LinkedClassRulesDetails.vue';
import { RouteName } from '@/modules/shared/types/routes';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const hasLinkedClassRulesEnabled = () => CustomerSettingsModule.settings.hasLinkedClassRulesEnabled;

export const LINKED_CLASSS_RULES_ROUTES = [
  {
    path: 'linked-class-rules/create',
    name: RouteName.linkedClassRulesCreate,
    meta: {
      authorities: [Authorities.LinkedClassRulesUpdate],
      isAllowed: hasLinkedClassRulesEnabled,
    },
    component: LinkedClassRulesDetails,
  },
  {
    path: 'linked-class-rules/:id',
    name: RouteName.linkedClassRulesDetails,
    meta: {
      authorities: [Authorities.LinkedClassRulesRead],
      isAllowed: hasLinkedClassRulesEnabled,
    },
    component: LinkedClassRulesDetails,
  },
];
