import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';

export const DETAILS_ROUTES: RouteRecordRaw[] = [
  {
    path: 'details',
    name: RouteName.details,
    meta: {
      authorities: [Authorities.FlightRead],
    },
    component: () => import('../views/FlightDetailsView.vue'),
  },
];
