<template>
  <vue-draggable class="k-draggable" filter=".disabled" :animation="250" data-test="draggable" :disabled="disabled">
    <template #item="{ element, index }">
      <k-draggable-item :disabled="element.disabled" :removable="itemsRemovable" @remove="emit('itemRemove', { element, index })">
        <slot :element="element" :index="index" />

        <template #remove>
          <slot name="remove" :element="element" :index="index" />
        </template>
      </k-draggable-item>
    </template>
  </vue-draggable>
</template>

<script setup lang="ts">
import VueDraggable from 'vuedraggable';

import { KDraggableRemoveItemEvent } from '@/modules/shared/components/draggable/KDraggable.types';
import KDraggableItem from '@/modules/shared/components/draggable/KDraggableItem.vue';

withDefaults(defineProps<{ itemsRemovable?: boolean; disabled?: boolean }>(), { itemsRemovable: true, disabled: false });
const emit = defineEmits<{ (e: 'itemRemove', v: KDraggableRemoveItemEvent): void }>();
</script>

<style lang="scss" scoped>
.k-draggable {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.show-lines .k-draggable-item:not(:last-child) {
  border-radius: 0;
  border-bottom: 1px solid var(--grey-6, #e5e5e5);
}
</style>
