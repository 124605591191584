<template>
  <div class="k-draggable-list-header">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'KDraggableListHeader',
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
  },
});
</script>

<style lang="scss">
.k-draggable-list-header {
  display: flex;
  min-height: 20px;
  align-items: center;
  width: 100%;
  padding: 0 40px 0 28px;
}
</style>
