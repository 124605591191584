import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { ApiPssCapabilities, CustomerSettingsModel } from '@/modules/api/customer-settings/customer-settings-contracts';
import { customerSettingsService } from '@/modules/api/customer-settings/customer-settings-service';
import { UiAndApiPssCapabilities } from '@/modules/shared/configuration/pss-capabilities';
import { getUiCapabilitiesByPss } from '@/modules/shared/configuration/pss-capabilities/get-by-pss';
import { store } from '@/store';

import { AppSettingsModule } from './app-settings.module';

export interface ICustomerSettingsState {
  settings: CustomerSettingsModel<UiAndApiPssCapabilities>;
}

@Module({ dynamic: true, store, name: 'customersettings', namespaced: true })
class CustomerSettings extends VuexModule implements ICustomerSettingsState {
  public settings: CustomerSettingsModel<UiAndApiPssCapabilities> = null;
  public hasMultiFactorAuthentication: boolean = null;

  // Actions
  @Action
  public async get() {
    try {
      const response: CustomerSettingsModel<ApiPssCapabilities> = await customerSettingsService.get();
      const combinedCapabilities = getUiCapabilitiesByPss(AppSettingsModule.inventoryConfigurationProperties.pss, response.pssCapabilities);

      const customerSettingsWithApiAndUiPssCapabilities: CustomerSettingsModel<UiAndApiPssCapabilities> = {
        ...response,
        pssCapabilities: combinedCapabilities,
      };

      this.setCustomerSettings(customerSettingsWithApiAndUiPssCapabilities);
    } catch (error) {
      return error;
    }
  }

  @Action
  public async getMultiFactorAuthentication() {
    try {
      const response = await customerSettingsService.getMultiFactorAuthentication();

      this.setMultiFactorAuthentication(response);
    } catch (error) {
      return error;
    }
  }

  // Mutations
  @Mutation
  public setCustomerSettings(payload: CustomerSettingsModel<UiAndApiPssCapabilities>) {
    this.settings = payload;
  }

  @Mutation
  public setMultiFactorAuthentication(payload: CustomerSettingsModel<ApiPssCapabilities>) {
    this.hasMultiFactorAuthentication = payload.hasMultiFactorAuthenticationEnabled;
  }
}

export const CustomerSettingsModule = getModule(CustomerSettings);
