import { isEmpty } from 'lodash-es';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { customerDefinedDataService } from '@/modules/customer-defined-data/services/customer-defined-data.service';
import { CustomerDefinedDataSchemaResponse } from '@/modules/customer-defined-data/services/customer-defined-data.service.model';
import { mapResponseToUIModel } from '@/modules/customer-defined-data/store/customer-defined-data-mapper';
import { CustomerDefinedDataUIItem } from '@/modules/customer-defined-data/store/customer-defined-data.module.model';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { store } from '@/store';

export function isSchemaEmpty(schema: CustomerDefinedDataSchemaResponse): boolean {
  return isEmpty(schema.flightFields) && isEmpty(schema.cabinFields);
}

@Module({ dynamic: true, store, name: 'customerDefinedData', namespaced: true })
export class CustomerDefinedDataModule extends VuexModule {
  public schema: CustomerDefinedDataSchemaResponse = { flightFields: {}, cabinFields: {} };
  public data: Map<string, CustomerDefinedDataUIItem> = new Map();

  public get byFlightKey() {
    return (flightKey: string): CustomerDefinedDataUIItem | undefined => this.data.get(flightKey);
  }

  @Action
  public async getSchema(): Promise<void> {
    try {
      const schema = await customerDefinedDataService.getSchema();
      this.setSchema(schema);
    } catch (error) {
      this.setSchema({ flightFields: {}, cabinFields: {} });
    }
  }

  /**
   * Retrieve the data by flight key and merge it with the schema, so it can be used easily in the application.
   */
  @Action
  public async getByFlightKey(flightKey: string): Promise<void> {
    if (this.byFlightKey(flightKey)) {
      return;
    }

    const response = await customerDefinedDataService.getByFlightKey(flightKey);
    if (isSchemaEmpty(this.schema)) {
      await this.getSchema();
    }

    const item = mapResponseToUIModel(response, this.schema);
    this.upsertData([item]);
  }

  @Action
  public async search(filters: FilterFieldDefinition[]): Promise<void> {
    try {
      const response = await customerDefinedDataService.search(filters);
      if (isSchemaEmpty(this.schema)) {
        await this.getSchema();
      }
      //map each response to the UI model and merge it with the existing data
      const items = response.map((response) => mapResponseToUIModel(response, this.schema));

      this.upsertData(items);
    } catch (error) {
      /* empty */
    }
  }

  @Mutation
  private upsertData(payload: CustomerDefinedDataUIItem[]): void {
    const newMap = new Map([...this.data]);
    payload.forEach((item) => newMap.set(item.flightKey, item));
    this.data = newMap;
  }

  @Mutation
  private setSchema(schema: CustomerDefinedDataSchemaResponse): void {
    this.schema = schema;
  }
}

export const customerDefinedDataModule = getModule(CustomerDefinedDataModule);
