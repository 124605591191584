import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export enum ErrorCode {
  DefaultError = -1,
  DoesNotExist = 1000,
  UniqueName = 1001,
  Unauthorized = 1002,
  NotFound = 1003,
  MissingParameter = 1004,
  EmptyValue = 1005,
  TooManyResults = 1006,
  NoFlights = 1007,
  EmptyList = 1008,
  DateRangesOverlap = 1009,
  InvalidDateFormat = 1010,
  InvalidTimeFormat = 1011,

  // Firebase
  FirebaseError = 1012,

  // User related errors
  InvalidPasswordUpdate = 1013,
  InvalidEmail = 1014,
  InvalidPhoneNumber = 1015,
  EmailAlreadyExist = 1016,
  PrivateUserUpdate = 1017,
  DuplicatePhoneNumber = 1018,
  UserInactive = 1019,

  // Promotion related errors
  InvalidDepartureDatesChange = 1020,
  InvalidStartDayChange = 1021,
  PromotionInvalidPricePoint = 1022,
  PromotionDepartureDateRangeAreTheSame = 1023,

  // FlowFx related Errors
  DefaultFlowExists = 1024,
  IllegalRouteAssignment = 1025,
  NoRoutesAssignedToFlow = 1026,
  InvalidDayOfWeek = 1027,

  /** TODO: Fix this double usage, defined like this in BE as well */
  IllegalNegativeValue = 1028,
  OnlyPositiveNumbers = 1028,

  InvalidClassCode = 1029,
  InvalidCabinCode = 1030,
  TriggerTypeWithScheduleCannotBeChanged = 1033,

  BatchImportJobRunning = 1031,

  AutomationHasNoActions = 1044,
  MaxRoutineNumberReached = 1047,
  EventBasedFlowCannotBeConvertedToRoutineOne = 1048,
  RoutineFlowCannotBeConvertedToEventBasedOne = 1049,

  DataImportMissingColumns = 2000,
  DataImportInvalidFields = 2001,

  DuplicateRouteClusterAssignment = 2500,

  IllegalRouteClusterAssignment = 2501,

  // Report related errors
  ReportingInvalidDayOfWeek = 3001,
  ReportingInvalidDayOfMonth = 3002,
  ReportingInvalidPickUpLimit = 3003,
  ReportingInvalidAggregationType = 3004,

  // Rules Errors
  RulesNonSequentialRank = 3500,
  RulesInvalidLinkedClass = 3501,
  RulesInvalidRival = 3502,

  //Config Errors
  RequiredUserAndReason = 4001,

  //Saved Query Errors
  UnsupportedGridFiltersWhenUsedByRoutine = 7000,
}

export const ErrorCodeMessages = new Map<number, string>();

export function loadErrorCodeMessages(): void {
  const messages: [ErrorCode, string][] = [
    [ErrorCode.BatchImportJobRunning, t('messages.batch_running')],
    [ErrorCode.DateRangesOverlap, t('form.validation_errors.date_ranges_overlap')],
    [ErrorCode.DefaultError, t('form.validation_errors.default')],
    [ErrorCode.DefaultFlowExists, t('flowfx.errors.default_flow_exists')],
    [ErrorCode.DuplicatePhoneNumber, t('form.validation_errors.duplicate_phone_number')],
    [ErrorCode.DuplicateRouteClusterAssignment, t('duplicate_route_cluster_assignment')],
    [ErrorCode.EmailAlreadyExist, t('form.validation_errors.unique_email')],
    [ErrorCode.EmptyList, t('form.validation_errors.unique_name')],
    [ErrorCode.InvalidDepartureDatesChange, t('form.validation_errors.departure_date_ranges_shortened')],
    [ErrorCode.InvalidEmail, t('form.validation_errors.invalid_email')],
    [ErrorCode.InvalidPasswordUpdate, t('auth_equal_passwords')],
    [ErrorCode.InvalidPhoneNumber, t('form.validation_errors.invalid_phone_number')],
    [ErrorCode.NoFlights, t('form.validation_errors.no_flights')],
    [ErrorCode.NoRoutesAssignedToFlow, t('flowfx.errors.no_routes_assigned_to_flow')],
    [ErrorCode.PrivateUserUpdate, t('form.validation_errors.private_user_update')],
    [ErrorCode.RequiredUserAndReason, t('form.validation_errors.required_user_and_reason')],
    [ErrorCode.TooManyResults, t('messages.refine_search')],
    [ErrorCode.Unauthorized, t('form.validation_errors.unauthorized')],
    [ErrorCode.UniqueName, t('form.validation_errors.unique_name')],
    [ErrorCode.UserInactive, t('form.validation_errors.user_inactive')],
    [ErrorCode.AutomationHasNoActions, t('form.validation_errors.automation_has_no_actions')],
    [ErrorCode.RequiredUserAndReason, t('form.validation_errors.required_user_and_reason')],
    [ErrorCode.MaxRoutineNumberReached, t('flowfx.errors.reached_max_number_of_routines')],
    [ErrorCode.EventBasedFlowCannotBeConvertedToRoutineOne, t('flowfx.errors.event_based_flow_cannot_be_converted_to_routine_one')],
    [ErrorCode.RoutineFlowCannotBeConvertedToEventBasedOne, t('flowfx.errors.routine_flow_cannot_be_converted_to_event_based_one')],
    [ErrorCode.TriggerTypeWithScheduleCannotBeChanged, t('flowfx.errors.trigger_type_with_schedule_cannot_be_changed')],
    [ErrorCode.UnsupportedGridFiltersWhenUsedByRoutine, t('query_toolbar.errors.unsupported_grid_filters')],
  ];

  messages.forEach((message) => {
    ErrorCodeMessages.set(message[0] as number, message[1] as string);
  });
}
