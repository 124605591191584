import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared/types/routes';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const hasAutomationsEnabled = () => CustomerSettingsModule.settings.hasAutomationsEnabled;

export const FLOW_FX_ROUTES: RouteRecordRaw[] = [
  {
    path: 'flows',
    name: RouteName.flows,
    redirect: { name: RouteName.flowsOverview },
    component: () => import('../views/FlowsView.vue'),
    meta: {
      authorities: [Authorities.FlowFxRead],
      isAllowed: hasAutomationsEnabled,
    },
    children: [
      {
        path: 'overview',
        name: RouteName.flowsOverview,
        component: () => import('../views/FlowsOverview.vue'),
      },
      {
        path: 'routines',
        name: RouteName.flowsRoutines,
        component: () => import('../views/RoutineFlows.vue'),
      },
      {
        path: 'archived',
        name: RouteName.flowsArchived,
        component: () => import('../views/ArchivedFlows.vue'),
      },
    ],
  },
  {
    path: 'flows/create',
    name: RouteName.flowCreate,
    meta: {
      authorities: [Authorities.FlowFxCreate],
      isAllowed: hasAutomationsEnabled,
    },
    component: () => import('../views/FlowCreate.vue'),
  },
  {
    path: 'flows/:id',
    name: RouteName.flowView,
    meta: {
      authorities: [Authorities.FlowFxRead],
      isAllowed: hasAutomationsEnabled,
    },
    component: () => import('../views/FlowDetails.vue'),
  },
];
