import { CellClassParams, ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import { isFinite, round } from 'lodash-es';

import { ColumnId, generateCabinLevelColumnId } from '@/models/enums/grid';
import { StringOrNumberComparator } from '@/modules/shared/utils/comparisons.utils';
import { i18n } from '@/plugins/i18n';
import { FlightService } from '@/services/flight.service';

import { NumberColumnFilterSettings, SetColumnFilterSettings } from './base';

const { t } = i18n.global;

export const generateCabinPacingPerformanceBandColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinPacingPerformanceBand),
  headerName: t('pacing_performance_band_short'),
  width: 60,
  minWidth: 60,
  hide: false,
  sortable: true,
  headerTooltip: t('pacing_performance_band'),
  cellClass: ({ data }: CellClassParams) => `data-test-cabin-perf-band-key-cell-${data.ondId} marginless-cell`,
  cellRenderer: 'GridPerformanceRenderer',
  cellRendererParams: (params: ValueFormatterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin) {
      return {
        performanceLabel: matchingCabin.performanceLabel,
        performanceScore: matchingCabin.performanceScore,
        linkToFlightStoreLoading: false,
      };
    }
  },
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);

    if (matchingCabin && matchingCabin.performanceLabel) {
      return matchingCabin.performanceLabel;
    }

    return undefined;
  },
  comparator: StringOrNumberComparator,
});

export const generateCabinPacingPerformanceScoreColumn = (cabinCode: string): ColDef => ({
  ...NumberColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinPacingPerformanceScore),
  headerName: t('pacing_performance_score_short'),
  width: 40,
  minWidth: 40,
  type: 'numericColumn',
  hide: false,
  sortable: true,
  headerTooltip: t('pacing_performance_score'),
  cellClass: ({ data }: CellClassParams) => `ag-right-aligned-cell data-test-cabin-perf-score-key-cell-${data.ondId}`,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin && isFinite(matchingCabin.performanceScore)) {
      return round(matchingCabin.performanceScore, 1);
    }

    return undefined;
  },
  comparator: StringOrNumberComparator,
});

export const generateCabinClusterColumn = (cabinCode: string): ColDef => ({
  ...SetColumnFilterSettings,
  colId: generateCabinLevelColumnId(cabinCode, ColumnId.CabinClusterColumn),
  headerName: t('cluster'),
  width: 100,
  minWidth: 100,
  type: 'leftAligned',
  hide: false,
  sortable: true,
  headerTooltip: t('cluster'),
  cellClass: ({ data }: CellClassParams) => `ag-left-aligned-cell data-test-cabin-cluster-key-cell-${data.ondId}`,
  valueGetter: (params: ValueGetterParams) => {
    const matchingCabin = FlightService.getMatchedCabin(params.data, cabinCode);
    if (matchingCabin && matchingCabin.cluster) {
      return matchingCabin.cluster.name;
    }

    return undefined;
  },
  comparator: StringOrNumberComparator,
});
