import { Authorities } from '@/modules/api/auth/auth-contracts';
import RulesOverview from '@/modules/rules/views/RulesOverview.vue';
import { RouteName } from '@/modules/shared/types/routes';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const hasLinkedClassRulesEnabled = () => CustomerSettingsModule.settings.hasLinkedClassRulesEnabled;

const hasRivalRulesEnabled = () => CustomerSettingsModule.settings.hasRivalRulesEnabled;

function getRouteMeta() {
  const rivalRulesAuthorities = hasRivalRulesEnabled || Authorities.RivalRulesRead;

  return {
    authorities: [hasLinkedClassRulesEnabled ? Authorities.LinkedClassRulesRead : rivalRulesAuthorities],
    isAllowed: hasLinkedClassRulesEnabled || hasRivalRulesEnabled,
  };
}

function getRedirect() {
  // If both Linked Class and Rival rule are not enabled go to Control
  // This practically shouldn't happen since the Rule link in the main menu won't appear
  const rivalRuleRedirect = hasRivalRulesEnabled ? { name: RouteName.rivalRulesOverview } : { name: RouteName.control };

  return hasLinkedClassRulesEnabled ? { name: RouteName.linkedClassRulesOverview } : rivalRuleRedirect;
}

export const RULES_ROUTES = [
  {
    path: 'rules',
    name: RouteName.rulesOverview,
    redirect: getRedirect(),
    meta: getRouteMeta(),
    component: RulesOverview,
    children: [
      {
        path: 'linked-class-rules',
        name: RouteName.linkedClassRulesOverview,
        component: () => import('../linked-class-rules/views/LinkedClassRulesOverview.vue'),
      },
      {
        path: 'rival-rules',
        name: RouteName.rivalRulesOverview,
        component: () => import('../rival-rules/views/RivalRulesOverview.vue'),
      },
    ],
  },
];
