import { ColGroupDef, ColumnState } from 'ag-grid-enterprise';

import {
  generateInventoryActiveOptimisationTacticsColumnsState,
  generateInventoryShadowOptimisationTacticsColumnsState,
  generateInventorySharedCabinClassSourceFlightColumnsState,
  generateInventorySharedFlightClassColumnsState,
  getInventoryActiveOptimisationTacticsColumns,
  getInventoryShadowOptimisationTacticsColumns,
  getInventorySharedCabinClassSourceFlightColumns,
  getInventorySharedFlightClassColumns,
} from '@/models/columns/collections/inventory/shared';
import * as CabinClassColumns from '@/models/columns/definitions';
import { ColumnGroupId, ColumnId } from '@/models/enums/grid';
import { i18n } from '@/plugins/i18n';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const { t } = i18n.global;

export const InventoryGridColumnDefinitions1y = (bookingsPickupDays: number[]): ColGroupDef[] => {
  const inventoryColumns = [
    {
      headerName: `${t('flight')} | ${t('class')}`,
      groupId: ColumnGroupId.InventoryFlightLineGroup,
      children: [
        ...getInventorySharedFlightClassColumns(bookingsPickupDays),
        CabinClassColumns.MinPriceColumn,
        CabinClassColumns.MaxPriceColumn,
        CabinClassColumns.NegotiatedAllottedSeatsColumn,
      ],
    },
    {
      headerName: `${t('flight')} | ${t('source_flight')}`,
      groupId: ColumnGroupId.ClassSourceFlightGroup,
      children: [...getInventorySharedCabinClassSourceFlightColumns()],
    },
    {
      headerName: `${t('active_optimisation_tactics')}`,
      groupId: ColumnGroupId.ActiveOptimisationTacticsGroup,
      children: [...getInventoryActiveOptimisationTacticsColumns()],
    },
  ];

  if (CustomerSettingsModule.settings.hasShadowTacticsEnabled) {
    inventoryColumns.push({
      headerName: t('shadow_tactics'),
      groupId: ColumnGroupId.ShadowOptimisationTacticsGroup,
      children: [...getInventoryShadowOptimisationTacticsColumns()],
    });
  }

  return inventoryColumns;
};

export const generateInventoryGridDefaultColumnState1y = (bookingsPickupDays: number[]): ColumnState[] => {
  const columns = [
    ...generateInventorySharedFlightClassColumnsState(bookingsPickupDays),
    { colId: ColumnId.MinPrice, hide: false, sort: null },
    { colId: ColumnId.MaxPrice, hide: false, sort: null },
    { colId: ColumnId.NegotiatedAllottedSeats, hide: true, sort: null },
    ...generateInventorySharedCabinClassSourceFlightColumnsState(),
    ...generateInventoryActiveOptimisationTacticsColumnsState(),
  ];

  if (CustomerSettingsModule.settings.hasShadowTacticsEnabled) {
    columns.push(...generateInventoryShadowOptimisationTacticsColumnsState());
  }
  return columns;
};
