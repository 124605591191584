import { ApiClient } from '@/modules/api/base-client';

import { FlightActionGroup, IFlightActionGroupsService } from './flight-action-groups-contracts';

export class FlightActionGroupsService implements IFlightActionGroupsService {
  private basePath = 'flight-action-groups';

  public async create(flightAction: FlightActionGroup): Promise<FlightActionGroup> {
    return await ApiClient.post<FlightActionGroup>(`/${this.basePath}`, flightAction);
  }

  public async edit(flightAction: FlightActionGroup): Promise<FlightActionGroup> {
    return await ApiClient.put<FlightActionGroup>(`/${this.basePath}/${flightAction.id}`, flightAction);
  }

  public async delete(flightActionId: number) {
    // the api returns the deleted action's id, but since
    // Axios.delete doesn't have return type
    // it gives an error if i type the return here
    return await ApiClient.delete(`/${this.basePath}/${flightActionId}`);
  }

  public async getAll(): Promise<FlightActionGroup[]> {
    return await ApiClient.get<FlightActionGroup[]>(`/${this.basePath}`);
  }

  // TODO: Make API endpoint that supports a put/edit for the whole collection
  public async saveAll(actionGroups: FlightActionGroup[]): Promise<FlightActionGroup[]> {
    return await Promise.all(
      actionGroups.map(
        async (group, index) =>
          await flightActionGroupsService.edit({
            ...group,
            groupOrder: index,
          }),
      ),
    );
  }
}

export const flightActionGroupsService = new FlightActionGroupsService();
