import { Environment } from '@/environment';
import { ConsoleLogService } from '@/modules/monitoring/services/console-log.service';
import { DatadogLogService } from '@/modules/monitoring/services/datadog-log.service';
import { NoopLogService } from '@/modules/monitoring/services/noop-log.service';
import { IAnalytics, IErrorLogger } from '@/modules/monitoring/types/logger.types';

/**
 * Instance of an ILogger based on the environment conditions.
 */
export class Logger {
  public static instance: IErrorLogger & IAnalytics;

  public static getInstance(): IErrorLogger & IAnalytics {
    if (!this.instance) {
      this.instance =
        Environment.logging === 'datadog'
          ? new DatadogLogService()
          : Environment.logging === 'console'
          ? new ConsoleLogService()
          : new NoopLogService();
    }

    return this.instance;
  }
}

export const logger = Logger.getInstance();
