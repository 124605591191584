import chroma from 'chroma-js';

import { ClassStructure } from '@/modules/api/application/application-contracts';

import { LafLoadFactorColoring } from '../api/user/user.contracts';

const COLOR_RANGE = ['B0D8A4', 'ffff99', 'E77383'];

const PICKUP_COLOR_RANGE = ['B0D8A4', 'ffffff', 'E77383'];

function luminateText(backGroundColor: string): boolean {
  return chroma(backGroundColor).luminance() < 0.5;
}

export function luminateTextColor(backGroundColor: string): string {
  return luminateText(backGroundColor) ? '#fff' : 'inherit';
}

const getLafLoadFactorColoring = (lafLoadFactorColoring: LafLoadFactorColoring) =>
  lafLoadFactorColoring === LafLoadFactorColoring.RED_TO_GREEN ? COLOR_RANGE : undefined;

export function generateLafColorScheme(lafLoadFactorColoring: LafLoadFactorColoring, classList: ClassStructure[]) {
  const colors: string[] = getLafLoadFactorColoring(lafLoadFactorColoring);

  return chroma
    .scale(colors)
    .colors(classList.length)
    .map((color, index) => ({
      color,
      class: classList[index].code,
    }));
}

export function generateLfColorScheme(lafLoadFactorColoring: LafLoadFactorColoring) {
  const colors = getLafLoadFactorColoring(lafLoadFactorColoring);
  return chroma.scale(colors).colors(100);
}

/**  The colors array is 51 long because the colors will be
 * distributed between -25 and +25 where:
 *
 * - anything equal or below -25 will be darkest red,
 * - 0 will be white,
 * - anything equal or above +25 will be the darkest green
 */
export function generatePickupColorScheme() {
  return chroma.scale(PICKUP_COLOR_RANGE).colors(51).reverse();
}

/** This scale is used for pickups that are 0 after _(bk - cancellation)_,
 * this scale shows a grey-scale based on _Math.max(bk, cancellations)_
 */
export function generateZeroPickupImpactColorScheme() {
  const ZERO_PICKUP_COLOR_RANGE = ['eee', 'aaa'];

  return chroma.scale(ZERO_PICKUP_COLOR_RANGE).colors(5);
}

/**  The colors array is 201 long because the colors will be
 * distributed between -100 and +100 where:
 *
 * - anything equal or below -100% will be darkest red,
 * - 0 will be white,
 * - anything equal or above +100% will be the darkest green
 */
export function generateCompetitorFareDifferenceColorScheme() {
  return chroma.scale(PICKUP_COLOR_RANGE).colors(201).reverse();
}
