import { ApiClient } from '@/modules/api/base-client';
import { FeaturesModel, IFeaturesService } from '@/modules/features/api/features/features.contracts';

export class FeaturesApiService implements IFeaturesService {
  private basePath = 'features';

  public async get(): Promise<FeaturesModel> {
    return ApiClient.get<FeaturesModel>(this.basePath);
  }
}

export const featuresApiService = new FeaturesApiService();
