export const en = {
  user_management: {
    titles: {
      user_management: 'User Management',
      edit_user: 'Edit User',
      add_user: 'Add User',
    },
    states: {
      loading_users: 'Loading Users...',
      active: 'Active',
      inactive: 'Inactive',
    },
    resource: {
      optimisation_profiles: 'Optimization Profiles',
      flights: 'Flights',
      users: 'Users',
      application: 'Application',
      route: 'Route',
      routes: 'Routes',
      system: 'System',
      promotions: 'Promotions',
      automations: 'FlowFx',
      rules: 'Rules',
      data_importer: 'External File Importer',
      revenue_components: 'Revenue Components',
    },
    role_name: {
      application: 'Application',
      application_admin: 'Application Admin',
      automations_admin: 'FlowFx Admin',
      automations_editor: 'FlowFx Editor',
      automations_viewer: 'FlowFx Viewer',
      flights_admin: 'Flights Admin',
      flights_editor: 'Flights Editor',
      flights_viewer: 'Flights Viewer',
      optimisation_profiles: 'Optimization Profiles',
      optimisation_profiles_admin: 'Optimization Profiles Admin',
      optimisation_profiles_viewer: 'Optimization Profiles Viewer',
      promotions_admin: 'Promotions Admin',
      promotions_viewer: 'Promotions Viewer',
      routes_admin: 'Routes Admin',
      routes_viewer: 'Routes Viewer',
      system_admin: 'System Admin',
      users_admin: 'Users Admin',
      users_creator: 'Users Creator',
      users_editor: 'User Editor',
      users_viewer: 'Users Viewer',
      rules_admin: 'Rules Admin',
      data_importer_admin: 'External File Importer Admin',
      revenue_components_admin: 'Revenue Components Admin',
    },
    labels: {
      name: 'Name',
      email: 'Email',
      status: 'Status',
      roles: 'Roles',
      actions: 'Actions',
      enroll_into_mfa: 'Enroll into MFA',
      phone_number: 'Phone Number',
      password: 'Password',
      active: 'Active',
      select_role: 'Select a Role',
    },
    info: {
      password_copy_alert:
        'Please copy the password and send it to the user. After saving this user, you cannot copy the password anymore.',
      at_least_one_role: 'Add at least one role.',
    },
    actions: {
      add_user: 'Add User',
      edit: 'Edit',
      reset: 'Reset',
      save: 'Save',
      cancel: 'Cancel',
      add_role: 'Add a Role',
      add_another_role: 'Add Another Role',
    },
  },
};
