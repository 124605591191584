import axios, { CancelTokenSource, CancelTokenStatic } from 'axios';
import { flatten, has, keyBy, merge, uniq, uniqBy, values } from 'lodash-es';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { GridFilterState } from '@/models/ag-grid/grid-state';
import { generateControlFilters } from '@/models/filters/collections/control';
import { ControlFilterType, filteredAirports } from '@/models/filters/utils';
import { AircraftTypeModel } from '@/modules/api/aircraft-types/aircraft-type-contracts';
import { aircraftTypeService as AircraftTypeService } from '@/modules/api/aircraft-types/aircraft-type-service';
import { CabinCode } from '@/modules/api/application/application-contracts';
import { RealtimeBookingSource } from '@/modules/api/customer-settings/customer-settings-contracts';
import { FlightLineModel } from '@/modules/api/flight/flight-contracts';
import { ondsService } from '@/modules/api/flight/onds-service';
import { QueryModel } from '@/modules/api/queries/query-contracts';
import { FilterField, FilterFieldField, FilterFieldType, RouteFilterType } from '@/modules/api/shared-contracts';
import {
  CrossFilterType,
  CrossfilterConfiguration,
  getCrossFilterConfigurations,
} from '@/modules/control/models/crossfilter/crossfilter-configuration';
import { customerDefinedDataModule } from '@/modules/customer-defined-data/store/customer-defined-data.module';
import { FilterFieldDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { logger } from '@/modules/monitoring';
import { Dictionary } from '@/modules/shared/types/generic';
import { TagsModule } from '@/modules/tags';
import { RoleModel } from '@/modules/user-management/api/role/role-contracts';
import { DateTimeService } from '@/services/date-time.service';
import { QueryTransformService } from '@/services/query-transform.service';
import { store } from '@/store';
import { AppSettingsModule } from '@/store/modules/app-settings.module';
import { ConfigurationModule } from '@/store/modules/configuration.module';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';
import { MarketInfoModule } from '@/store/modules/market-info.module';
import { RouteGroupsModule } from '@/store/modules/route-groups.module';
import { UserConfigModule } from '@/store/modules/user-config.module';

/**
 * This is the state that we actually use to store the crossfilter state in the Control Module.
 * We need to store the associated cabinCode as well to be able to restore the crossfilter state.
 */
export type CrossfilterState = {
  /**
   * Unique identifier for the crossfilter that also contains information about the associated cabinCode.
   */
  id: string;
  widget: CrossFilterType;
  selection: number[] | string[] | Date[];
  cabinCode?: string;
};

// State definition
export interface IControlState {
  flightLines: ReadonlyArray<FlightLineModel>;
  filteredFlightLines: ReadonlyArray<FlightLineModel>;
  selectedFlightLineIds: number[];
  selectedRowsCount: number;
  isLoading: boolean;
  filters: FilterFieldDefinition[];
  filtersFinal: FilterFieldDefinition[];
  flightReviewUpdated: boolean;
  activeQuery: QueryModel | null;
  isResultEmpty: boolean;
  gridFilterState: GridFilterState | null;
  crossfilterStates: CrossfilterState[];
}

@Module({ dynamic: true, store, name: 'control', namespaced: true })
class Control extends VuexModule implements IControlState {
  /**
   * The captureDate is a mandatory field in the FilterDefinition[] for the onds/search endpoint.
   * @note according to the documentation, the departureDate is mandatory (api/src/main/kotlin/com/kambr/eddy/api/ond/search/README.md)
   */
  public get filtersFinal(): FilterFieldDefinition[] {
    return [
      ...this.filters,
      {
        field: FilterFieldField.captureDate,
        type: FilterFieldType.equal,
        value: ConfigurationModule.captureDate,
      },
    ];
  }

  public get selectedHubs(): FilterFieldDefinition[] {
    const hubFilter = this.filters.find((filter) => filter.field === FilterFieldField.hub);
    return hubFilter && hubFilter.value;
  }

  public get selectedFlightLines(): FlightLineModel[] {
    return (
      this.selectedFlightLineIds
        .map((flId) => this.flightLines.find((fl) => fl.id === flId))
        .filter((flightLine): flightLine is FlightLineModel => !!flightLine) ?? []
    );
  }

  public get assignedFlightLines(): FlightLineModel[] {
    // Return flightLines that are assigned to the user, unless the user has the Admin role then return all flights
    // If assignedRoutes collection is empty it means all routes are assigned
    return UserConfigModule.user.roles.find((role: RoleModel) => role.name === 'Flights.Admin') ||
      !UserConfigModule.user.assignedRoutes?.length
      ? this.selectedFlightLines
      : this.selectedFlightLines.filter((flightLine) =>
          UserConfigModule.user.assignedRoutes?.find((assignedRoute) => assignedRoute.flightPath === flightLine?.flightPath),
        );
  }

  /**
   * The application has specific crossfilters available based on some customer's settings.
   */
  public get allAvailableCrossfilterConfigurations(): CrossfilterConfiguration[] {
    const hasRealtimeEnabled =
      CustomerSettingsModule.settings?.realtimeBookingSource === RealtimeBookingSource.STREAMED ||
      CustomerSettingsModule.settings?.realtimeBookingSource === RealtimeBookingSource.SCHEDULED;

    return getCrossFilterConfigurations({
      hasAirAsiaDataScienceIntegrationEnabled: CustomerSettingsModule.settings?.hasAirAsiaDataScienceIntegrationEnabled,
      hasRealtimeEnabled,
      hasShadowTacticsEnabled: CustomerSettingsModule.settings?.hasShadowTacticsEnabled,
      cabinCodes: AppSettingsModule.inventoryConfigurationProperties?.cabins.map((a) => a.code as CabinCode),
    });
  }

  public get filterGroups(): ControlFilterType[] {
    const filters: FilterFieldField[] = this.filters.map((filter) => filter.field as FilterFieldField);
    return uniq(
      filters
        ?.map((filter) => {
          switch (filter) {
            case FilterFieldField.origin:
            case FilterFieldField.destination:
            case FilterFieldField.hub:
            case FilterFieldField.flightPath:
              return ControlFilterType.origin_destination_hub;
            case FilterFieldField.dayOfWeek:
              return ControlFilterType.day_of_week;
            case FilterFieldField.departureDateRange:
              return ControlFilterType.departure_date_range;
            case FilterFieldField.tagId:
              return ControlFilterType.tags;
            case FilterFieldField.userId:
              return ControlFilterType.users;
            case FilterFieldField.aircraftType:
              return ControlFilterType.aircraft_type;
            case FilterFieldField.flightNumber:
              return ControlFilterType.flightNumber;
            case FilterFieldField.routeGroupId:
              return ControlFilterType.route_group;
            case FilterFieldField.carrierCode:
              return ControlFilterType.carrier_code;
            case FilterFieldField.optimizationProfile:
              return ControlFilterType.optimization_profile;
            case FilterFieldField.optimizationTactic:
              return ControlFilterType.optimization_tactic;
            case FilterFieldField.cluster:
              return ControlFilterType.cluster;
            default:
              break;
          }
        })
        .filter((filter): filter is ControlFilterType => !!filter),
    );
  }

  // Default state
  public flightLines: ReadonlyArray<FlightLineModel> = [];
  public filteredFlightLines: ReadonlyArray<FlightLineModel> = [];
  public selectedFlightLineIds: number[] = [];
  public selectedRowsCount = 0;
  public isLoading = false;
  public flightReviewUpdated = false;
  public aircraftTypes: string[] = [];
  public areFiltersValid = true;
  public activeQuery: QueryModel | null = null;
  public hasActiveQuery = false;
  public isResultEmpty = false;
  public gridFilterState: GridFilterState | null = null;
  public crossfilterStates: CrossfilterState[] = [];
  public filters: FilterFieldDefinition[] = [];
  public CancelToken?: CancelTokenStatic;
  public source?: CancelTokenSource;

  @Action
  public async filtersApplied(
    params = {
      page: 0,
      size: 20000,
    },
  ): Promise<unknown> {
    let response: FlightLineModel[];
    this.setSelectedRows([]);
    this.setLoadingState(true);

    this.source = axios.CancelToken.source();

    try {
      [response] = await Promise.all([
        ondsService.searchAll(this.filtersFinal, this.source.token, params),
        CustomerSettingsModule.settings.hasCustomerDefinedDataEnabled ? customerDefinedDataModule.search(this.filtersFinal) : null,
      ]);

      this.setPins(response);
      this.setFlightLines(response);
      /**
       * Use `this.flightLines` set by `this.setFlightLines`, since that mutation enriches the flight lines with customer defined data
       * using the response directly would require us to set it in the filtered flight lines as well
       */
      this.setFilteredFlightLines(this.flightLines);
    } catch (err) {
      return err;
    } finally {
      this.setLoadingState(false);
    }
    return response;
  }

  @Action
  public clearSearchAndQuery(): void {
    this.clearFlightLines();
    this.resetQueryFilters();
    this.clearGridFilterAndSortState();
    this.deselectQuery();
  }

  @Action
  public setSelectedRows(flightLineIds: number[]): void {
    this.setSelectedFlightLines(flightLineIds);
    this.setSelectedRowsCount(flightLineIds.length);
  }

  /**
   * Here we map the list of user selected crossfilters to actual crossfilter state objects.
   * Make sure that the user selected widget types are still supported by the application by filtering them out.
   */
  @Action
  public async setDefaultCrossfilterStates(): Promise<void> {
    const allCfConfigs = [...this.allAvailableCrossfilterConfigurations];

    const activeFilters: CrossfilterState[] = (UserConfigModule.controlSettings.crossfilterState || [])
      ?.map((userConfig) => {
        const filter = allCfConfigs.find(
          (state) => state.widgetType === userConfig.widget && state.widgetParams?.cabinCode === userConfig?.cabinCode,
        );

        if (!filter) return;

        const state: CrossfilterState = {
          id: filter.id,
          widget: filter.widgetType,
          selection: [],
          cabinCode: filter.widgetParams?.cabinCode,
        };
        return state;
      })
      .filter((a): a is CrossfilterState => Boolean(a));

    await this.setActiveCrossfilters(activeFilters);
  }

  /**
   * Merge the current state with the new state and update the store.
   */
  @Action
  public async updateActiveCrossfilters(crossfilterStates: CrossfilterState[]): Promise<void> {
    const newCrossfilterStates = values(merge(keyBy(this.crossfilterStates, 'id'), keyBy(crossfilterStates, 'id')));
    await this.setActiveCrossfilters(newCrossfilterStates);
  }

  /**
   * Set the crossfilters that are visible in the application and update the User Settings
   * so the user can see the same crossfilters when he logs in again.
   */
  @Action
  public async setActiveCrossfilters(filters: CrossfilterState[]): Promise<void> {
    const uniqueFilters = uniqBy(filters, 'id');
    this.setCrossfilterConfiguration(uniqueFilters);

    await UserConfigModule.saveControlSettings({
      crossfilterState: uniqueFilters.map((a) => ({
        widget: a.widget,
        cabinCode: a.cabinCode,
      })),
    });
  }

  /**
   * Reset the crossfilters to their default state (no selections or empty, N/A filters applied - or any other future filter criteria).
   */
  @Action
  public resetActiveCrossfilters(): void {
    const newState = this.crossfilterStates.map((cf) => ({
      ...cf,
      selection: [],
    }));
    this.setCrossfilterConfiguration(newState);
  }

  @Action
  public updateCrossfilterState(payload: CrossfilterState): void {
    const widget = this.crossfilterStates.find((cfState) => cfState.id === payload.id);

    if (widget) {
      widget.selection = payload.selection;

      this.setCrossfilterState(widget);
    }
  }

  @Mutation
  private setCrossfilterConfiguration(payload: CrossfilterState[]): void {
    this.crossfilterStates = [...payload];
  }

  @Mutation
  private setCrossfilterState(payload: CrossfilterState): void {
    const updatedCrossFilterStates = [...this.crossfilterStates];

    updatedCrossFilterStates.splice(
      updatedCrossFilterStates.findIndex((cfState) => cfState.id === payload.id),
      1,
      payload,
    );

    this.crossfilterStates = updatedCrossFilterStates;
  }

  @Action
  public async getStateFilters(): Promise<FilterFieldDefinition[]> {
    return this.filters?.length > 0 ? this.filters : await this.getDefaultQueryFilters();
  }

  @Action
  public async getDefaultQueryFilters(queryParameters: Dictionary<string | string[]> = {}): Promise<FilterFieldDefinition[]> {
    // Check if the query parameters does change the current active Market Filter Type (e.g. from OnD to Hub).
    if (has(queryParameters, FilterFieldField.hub)) {
      UserConfigModule.changeControlFilterAction(RouteFilterType.hub);
    } else if (has(queryParameters, FilterFieldField.origin)) {
      UserConfigModule.changeControlFilterAction(RouteFilterType.origin_destination);
    }

    if (UserConfigModule.controlSettings?.routeFilterType === RouteFilterType.hub) {
      UserConfigModule.changeControlFilterAction(RouteFilterType.hub);
    } else if (UserConfigModule.controlSettings?.routeFilterType === RouteFilterType.origin_destination) {
      UserConfigModule.changeControlFilterAction(RouteFilterType.origin_destination);
    }
    this.resetQueryFilters();

    if (Object.keys(queryParameters).length > 0) {
      this.applyUrlParamsToQueryFilters(queryParameters);
    }
    if (this.flightReviewUpdated) {
      await this.filtersApplied();
    }

    return this.filters;
  }

  @Action
  public updateFilter(payload: { value: any; index: number }): void {
    this.setFilter(payload);

    if (this.filters[payload.index].field === FilterFieldField.origin) {
      this.refilterDestinations(payload.value);
    }

    if (this.filters[payload.index].field === FilterFieldField.destination) {
      this.refilterOrigins(payload.value);
    }
  }

  @Action
  public updateFilterValidity(payload: { value: { value: any; type: FilterFieldType }; index: number }): void {
    this.setFilterValidity(payload);
  }

  @Action
  public clearFlightLines(): void {
    this.setFlightLines([]);
    this.setFilteredFlightLines([]);
  }

  @Action
  public updateFlightReviewUpdated(bool: boolean): void {
    this.setFlightReviewUpdated(bool);
  }

  @Action
  public changeLoadingState(payload: boolean): void {
    this.setLoadingState(payload);
  }
  @Action
  public updateGridFilterState(state: GridFilterState | null): void {
    this.setGridFilterAndSortState(state);
  }

  @Action
  public clearGridFilterAndSortState(): void {
    this.setGridFilterAndSortState(null);
  }

  @Action
  public cancelSearch(): void {
    this.source?.cancel();
    this.setLoadingState(false);
  }

  @Action
  public async getAircraftTypes(): Promise<void> {
    const aircraftTypes = await AircraftTypeService.getAll();
    this.setAircraftTypes(aircraftTypes);
  }

  @Mutation
  public setGridFilterAndSortState(state: GridFilterState | null): void {
    this.gridFilterState = state;
  }

  @Mutation
  private setFlightPaths(selectedHubs: string[]): void {
    const flightPathFilter = this.filters.find((filter) => filter.field === FilterFieldField.flightPath);
    if (flightPathFilter) {
      flightPathFilter.value = uniq(
        flatten(MarketInfoModule.uniqueHubs.filter((hub) => selectedHubs.indexOf(hub.code) !== -1).map((hub) => hub.flightPaths)),
      );
    }
  }

  @Mutation
  public setHubs(payload: string[]): void {
    const hubFilter = this.filters.find((filter) => filter.field === FilterFieldField.hub);
    if (hubFilter) {
      hubFilter.value = payload;
    }
  }

  @Mutation
  public clearFlightPaths(): void {
    const flightPathFilter = this.filters.find((filter) => filter.field === FilterFieldField.flightPath);
    if (flightPathFilter) {
      flightPathFilter.value = [];
    }
  }

  @Mutation
  public setFilter(payload: { value: any; index: number }): void {
    this.filters[payload.index].value = payload.value;
  }

  @Mutation
  public setFilterValidity(payload: { value: any; index: number }): void {
    this.filters[payload.index].isValid = payload.value;
    this.areFiltersValid = !this.filters.some((fd) => fd.isValid === false);
  }

  @Mutation
  public setOperator(payload: { value: any; index: number }): void {
    this.filters[payload.index].type = payload.value;
  }

  @Mutation
  public setFlightLines(flightLines: ReadonlyArray<FlightLineModel>): void {
    this.isResultEmpty = flightLines.length === 0;

    const newFlightLines = CustomerSettingsModule.settings?.hasCustomerDefinedDataEnabled
      ? flightLines.map((flightLine) => ({
          ...flightLine,
          customerDefinedData: customerDefinedDataModule.byFlightKey(flightLine.flightKey),
        }))
      : [...flightLines];

    this.flightLines = Object.freeze(newFlightLines);
  }

  @Mutation
  public setSelectedFlightLines(flightLineIds: number[]): void {
    this.selectedFlightLineIds = flightLineIds;
  }

  @Mutation
  public setFilteredFlightLines(flightLines: ReadonlyArray<FlightLineModel>): void {
    this.filteredFlightLines = Object.freeze([...flightLines]);
  }

  @Mutation
  public setSelectedRowsCount(rowCount: number): void {
    this.selectedRowsCount = rowCount;
  }

  @Mutation
  public setAircraftTypes(aircraftTypes: AircraftTypeModel[]): void {
    const aircraftTypeFilter = this.filters.find((filter) => filter.field === FilterFieldField.aircraftType);

    const types = aircraftTypes.map((aircraftType: AircraftTypeModel) => aircraftType.type);

    if (aircraftTypeFilter) {
      aircraftTypeFilter.componentDataOptions = types;
    }

    this.aircraftTypes = types;
  }

  /**
   * Reset the current search filters. Take the User's configured filters into account.
   */
  @Mutation
  public resetQueryFilters(): void {
    const defaultControlFilters: FilterFieldDefinition[] = generateControlFilters(TagsModule.tags);

    const orderedFilters: FilterFieldDefinition[] = UserConfigModule.controlSettings.filters
      ?.map((filterName) => {
        if (filterName === FilterFieldField.flightNumberRange) {
          filterName = FilterFieldField.flightNumber;
        }
        return defaultControlFilters.find((filter) => filter.field === filterName);
      })
      .filter(Boolean) as FilterFieldDefinition[];

    this.filters = orderedFilters.map((def: FilterFieldDefinition) => {
      switch (def.field) {
        case FilterFieldField.origin:
        case FilterFieldField.destination:
          def.componentDataOptions = filteredAirports(def.field);
          def.value = [];
          break;
        case FilterFieldField.hub:
        case FilterFieldField.flightPath:
          def.componentDataOptions = MarketInfoModule.uniqueHubs;
          def.value = [];
          break;
        case FilterFieldField.departureDateRange:
          def.value = [
            DateTimeService.StripUTCOffset({
              date: ConfigurationModule.captureDate,
            }),
            DateTimeService.StripUTCOffset({
              date: ConfigurationModule.captureDate,
            }),
          ];
          break;
        case FilterFieldField.tagId:
          def.componentDataOptions = [...TagsModule.tags];
          def.value = [];
          break;
        case FilterFieldField.userId:
          def.value = null;
          break;
        case FilterFieldField.aircraftType:
          def.componentDataOptions = [...this.aircraftTypes];
          def.value = [];
          break;
        case FilterFieldField.routeGroupId:
          def.componentDataOptions = [...RouteGroupsModule.allRouteGroups];
          def.value = [];
          break;
        case FilterFieldField.carrierCode:
          def.componentDataOptions = ConfigurationModule.carrierCodes;
          def.value = [];
          break;
        case FilterFieldField.dayOfWeek:
        case FilterFieldField.optimizationProfile:
        case FilterFieldField.optimizationTactic:
        case FilterFieldField.cluster:
          def.value = [];
          break;
        default:
          def.value = '';
          break;
      }
      return def;
    });
  }

  /**
   * Convert the Query to the associated search filters, crossfilters and gridstate and replace the current state with the new state.
   */
  @Action
  public async selectQuery(selectedQuery: QueryModel): Promise<void> {
    // In some queries the flightNumber is stored as flightNumberRange.
    // That shouldn't be, but for now make sure the filter bar keeps working when a query has flightNumberRange defined
    const flightNumberRangeField = selectedQuery.query.fields.find(
      (query: FilterField) => query.field === FilterFieldField.flightNumberRange,
    );
    if (flightNumberRangeField) {
      flightNumberRangeField.field = FilterFieldField.flightNumber;
    }

    // Set the selected query as the active query
    this.setActiveQuery(selectedQuery);

    // Set the active cross filters - (can't put this in a private helper method - Vuex strips these out).
    const activeCrossFilters: CrossfilterState[] = [];
    for (const queryState of selectedQuery.crossfilterState) {
      /**
       * Get the actual cross filter configuration by the query state.
       * We do this because the querystate does not contain an ID that we can match on.
       * TODO: (KB) align this model with the {@link CrossfilterConfiguration}
       */
      const cfConf = this.allAvailableCrossfilterConfigurations.find(
        (config) => config.widgetType === queryState.widget && config.widgetParams?.cabinCode === queryState?.cabinCode,
      );
      if (cfConf) {
        activeCrossFilters.push({
          id: cfConf.id,
          widget: cfConf.widgetType,
          selection: queryState?.selection ?? [],
          cabinCode: queryState?.cabinCode,
        });
      }
    }

    const newQueryFilters: FilterFieldField[] = UserConfigModule.controlSettings.filters || [];

    // This will set filters fields in the view when they have a value and are not enabled in the view in the sidebar config section.
    (selectedQuery.query?.fields as FilterField[])
      .map((filter: FilterField) => filter.field as FilterFieldField)
      .map((filterField: FilterFieldField) => {
        if (!newQueryFilters.includes(filterField)) {
          newQueryFilters.unshift(filterField);
        }
      });

    const queryIndex = newQueryFilters.findIndex((query) => query === FilterFieldField.departureDate);

    if (queryIndex > -1) {
      newQueryFilters.splice(queryIndex, 1);
    }

    // Set control config values in the saved settings
    const newControlSettings = {
      filters: newQueryFilters,
      crossfilterState: merge(
        UserConfigModule.controlSettings.crossfilterState,
        activeCrossFilters.map((a) => ({
          widget: a.widget,
          cabinCode: a.cabinCode,
        })),
      ),
      competitors: selectedQuery.competitors,
      groupKey: selectedQuery.groupKey,
      routeFilterType: selectedQuery.routeFilterType,
    };

    await UserConfigModule.saveControlSettings(newControlSettings);

    await UserConfigModule.saveUserSettings({
      controlBookingsPickUpPoints: selectedQuery.bookingsPickups,
      controlPerformanceBandPickUpPoints: selectedQuery.performanceBandPickups,
      controlSettings: newControlSettings,
    });

    this.resetQueryFilters();
    const query = QueryTransformService.transformToParams(selectedQuery.query.fields as FilterFieldDefinition[]);

    await this.getDefaultQueryFilters(query);

    this.setCrossfilterConfiguration(activeCrossFilters);

    // Set the grid filter state
    this.setGridFilterAndSortState(selectedQuery.queryGridFilterState ?? null);

    // Set the column state
    await UserConfigModule.saveControlGridColumnStateSession(selectedQuery.queryGridColumnState);
  }

  @Action
  public async deselectQuery(): Promise<void> {
    this.setActiveQuery(null);
    await this.setDefaultCrossfilterStates();
  }

  @Mutation
  public setLoadingState(isLoading: boolean): void {
    this.isLoading = isLoading;
  }

  @Mutation
  public setPins(flightLines: FlightLineModel[]): void {
    flightLines.forEach((flightLine) => {
      flightLine.pins = 0;

      flightLine.cabins?.forEach((cabin) => {
        cabin.sumOfPinnedClasses = cabin.classes.filter((cls) => cls.isAuthorizationUnitPinned || cls.isProtectionPinned).length;

        (flightLine.pins as number) += cabin.sumOfPinnedClasses;
      });
    });
  }

  @Mutation
  public refilterOrigins(newVal: string[]): void {
    const originFilter = this.filters.find((filter) => filter.field === FilterFieldField.origin);
    if (originFilter) {
      originFilter.componentDataOptions =
        newVal.length === 0 ? filteredAirports(FilterFieldField.origin) : filteredAirports(FilterFieldField.destination, newVal);
    } else {
      logger.error(new Error(`Origin filter not found while re-filtering origins with value`), newVal);
    }
  }

  @Mutation
  public refilterDestinations(newVal: string[]): void {
    const destinationFilter = this.filters.find((filter) => filter.field === FilterFieldField.destination);
    if (destinationFilter) {
      destinationFilter.componentDataOptions =
        newVal.length === 0 ? filteredAirports(FilterFieldField.destination) : filteredAirports(FilterFieldField.origin, newVal);
    }
  }

  @Mutation
  private applyUrlParamsToQueryFilters(params: Record<string, string | string[]>): void {
    Object.keys(params).forEach((key) => {
      const field: FilterFieldDefinition | undefined = this.filters.find((ffd) => ffd.field === key);
      // A querystring can contain multiple values for the same key, so we need to check for that.
      // We do not support that for the filters, so we just take the first value.
      const paramValue: string = Array.isArray(params[key]) ? params[key]?.[0] : (params[key] as string) ?? '';
      if (field) {
        field.value = field.transformFromParams ? field.transformFromParams(paramValue, this.filters, params) : field.value;
      }
    });
  }

  @Mutation
  public setFlightReviewUpdated(bool: boolean): void {
    this.flightReviewUpdated = bool;
  }

  @Mutation
  public setActiveQuery(payload: QueryModel | null): void {
    this.activeQuery = payload;
    this.hasActiveQuery = !!payload;
  }
}

export const ControlModule = getModule(Control);
