<template>
  <div class="mb-2">
    <k-draggable
      :model-value="modelValue"
      item-key="rivalCarrierCode"
      class="w-full mr-7 show-lines"
      :items-removable="!disabled"
      :disabled="disabled"
      @update:model-value="emit('update:modelValue', $event)"
      @item-remove="onItemRemoved"
    >
      <template #default="{ element, index }: { element: RivalRuleDetailModel, index: number }">
        <rival-rule-detail-list-item
          :model-value="element"
          :carrier-codes="carrierCodes"
          :disabled="disabled"
          @update:model-value="onRivalChange($event, index)"
        />
      </template>
    </k-draggable>
  </div>
</template>

<script lang="ts" setup>
import RivalRuleDetailListItem from '@/modules/rules/rival-rules/components/RivalRuleDetailListItem.vue';
import { RivalRuleDetailModel } from '@/modules/rules/rival-rules/models/rival-rule.model';
import { KDraggableRemoveItemEvent } from '@/modules/shared/components/draggable/KDraggable.types';
import KDraggable from '@/modules/shared/components/draggable/KDraggable.vue';

const props = defineProps<{ modelValue: RivalRuleDetailModel[]; carrierCodes: string[]; disabled?: boolean }>();
const emit = defineEmits<{ (e: 'update:modelValue', v: RivalRuleDetailModel[]): void }>();

function onItemRemoved({ index }: KDraggableRemoveItemEvent): void {
  const rules = [...props.modelValue];
  rules.splice(index, 1);
  emit('update:modelValue', rules);
}

function onRivalChange(rival: RivalRuleDetailModel, index: number): void {
  const updatedRules = [...props.modelValue];
  updatedRules[index] = rival;
  emit('update:modelValue', updatedRules);
}
</script>
