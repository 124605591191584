import axios from 'axios';
import { getActivePinia } from 'pinia';
import { Action, Module, Mutation, VuexModule, getModule } from 'vuex-module-decorators';

import { Authorities, LoginRequestModel } from '@/modules/api/auth/auth-contracts';
import { authService } from '@/modules/api/auth/auth-service';
import { ClusterManagementModule } from '@/modules/cluster-management/store/cluster-management';
import { customerDefinedDataModule } from '@/modules/customer-defined-data/store/customer-defined-data.module';
import { FeaturesModule } from '@/modules/features/store/modules/features.module';
import { FlightActionsModule } from '@/modules/flight-actions/store/modules/flight-actions.module';
import { logger } from '@/modules/monitoring';
import { useGlobalLinkedClassRule } from '@/modules/rules/linked-class-rules/composables/use-linked-class-rule-loader.composable';
import { useGlobalRivalRule } from '@/modules/rules/rival-rules/composables/use-rival-rule-loader.composable';
import { CalendarModule } from '@/modules/shared/store/modules/Calendar';
import { SystemConfigModel } from '@/modules/system-settings/api/system/system.contracts';
import { TagsModule } from '@/modules/tags';
import { JwtService } from '@/services/jwt.service';
import { store } from '@/store';

import { AppSettingsModule } from './app-settings.module';
import { ConfigurationModule } from './configuration.module';
import { ControlModule } from './control.module';
import { CustomerSettingsModule } from './customer-settings.module';
import { FlightNumbersModule } from './flight-numbers.module';
import { MarketInfoModule } from './market-info.module';
import { OptimisationProfilesModule } from './optimisation-profiles.module';
import { RouteGroupsModule } from './route-groups.module';
import { UserConfigModule } from './user-config.module';

// State definition
export interface IAuthState {
  token: string;
  email: string;
  isAuthenticating: boolean;
  loginErrorMessage: string | null;
}

@Module({ dynamic: true, store, name: 'auth', namespaced: true })
class Auth extends VuexModule implements IAuthState {
  // Default state
  public token: string = localStorage.getItem('token') || '';
  public username = '';
  public email = '';
  public hasFetchedSettings = false;
  public isAuthenticating = false;
  public loginErrorMessage: string | null = null;

  // Actions
  @Action
  public async login(credentials: LoginRequestModel): Promise<string | null> {
    this.setAuthenticating(true);
    this.setLoginErrorMessage(null);

    try {
      const response = await authService.login(credentials);
      const newToken = response.access_token;
      this.setToken(newToken);
      localStorage.setItem('token', newToken);

      const config = await this.getSettings();

      if (config) {
        return newToken;
      } else {
        this.setLoginErrorMessage('messages.unable_to_get_system_config');
        return null;
      }
    } catch (error) {
      this.setLoginErrorMessage('messages.unable_to_log_in');
      return null;
    } finally {
      this.setAuthenticating(false);
    }
  }

  @Action
  public async getToken(credentials: LoginRequestModel): Promise<string | null> {
    try {
      const response = await authService.login(credentials);
      const newToken = response.access_token;
      this.setToken(newToken);
      localStorage.setItem('token', newToken);

      return newToken;
    } catch (error) {
      this.setLoginErrorMessage('messages.unable_to_log_in');
      return null;
    }
  }

  @Action
  public async getSettings(): Promise<SystemConfigModel | null> {
    const decodedToken = JwtService.decodeToken(this.token);
    this.setUsername(decodedToken.name);
    this.setEmail(decodedToken.user_name);

    await AppSettingsModule.getApplicationSettings();
    // Customer settings need to be loaded before User configuration
    // OwnFareSource is needed to set column state
    await CustomerSettingsModule.get();

    await Promise.all([
      // get feature settings
      FeaturesModule.getFeatures(),
      // open
      CustomerSettingsModule.getMultiFactorAuthentication(),
      // route:read flight:read
      authService.hasAuthority([Authorities.RouteRead, Authorities.FlightRead], decodedToken.authorities)
        ? MarketInfoModule.getMarketInfo()
        : null,
      UserConfigModule.getUserConfiguration(decodedToken.user_name),
      // open
      OptimisationProfilesModule.getAll(),
      // open
      ControlModule.getAircraftTypes(),
      // open
      RouteGroupsModule.getAll(),
      // tag:read
      authService.hasAuthority([Authorities.TagRead], decodedToken.authorities) ? TagsModule.get() : null,
      // open
      FlightActionsModule.getFlightActionGroups(),
      // open
      FlightNumbersModule.getAll(),
      // ('clusters:read') or ('route:read') or ('flight:read')
      authService.hasAuthority([Authorities.ClustersRead, Authorities.RouteRead, Authorities.FlightRead], decodedToken.authorities)
        ? ClusterManagementModule.getAllClusters()
        : null,
      // linked-class-rules:read
      authService.hasAuthority([Authorities.LinkedClassRulesRead], decodedToken.authorities) ? useGlobalLinkedClassRule().getAll() : null,
      // rival-rules:read
      authService.hasAuthority([Authorities.RivalRulesRead], decodedToken.authorities) ? useGlobalRivalRule().getAll() : null,
      // open
      CustomerSettingsModule.settings?.hasCustomerDefinedDataEnabled ? customerDefinedDataModule.getSchema() : null,
    ]);

    const config = await ConfigurationModule.getConfiguration();
    if (config) await CalendarModule.setSeasonsAction(config.captureDate);

    this.setHasFetchedSettings(true);

    return config;
  }

  @Action
  public logOut(): void {
    this.clearModules();

    const activeStores = getActivePinia();

    if (activeStores) {
      // _s does exist and stores the Pinia stores, but it's internal so let's see for how long this keeps working 🤞
      // @ts-ignore
      activeStores?._s?.forEach((store: any) => {
        try {
          store.$reset();
        } catch (e) {
          logger.error(e as Error);
        }
      });
    }

    // Make sure requests won't have the 'invalid' token anymore
    delete axios.defaults.headers.common.Authorization;
    localStorage.removeItem('token');
    localStorage.removeItem('gridColumnStates');
    this.setToken('');
  }

  // Mutations
  @Mutation
  private setToken(token: string): void {
    this.token = token;
  }

  @Mutation
  private clearModules(): void {
    ControlModule.clearSearchAndQuery();
  }

  @Mutation
  private setEmail(email: string): void {
    this.email = email;
  }

  @Mutation
  private setUsername(username: string): void {
    this.username = username;
  }

  @Mutation
  private setHasFetchedSettings(hasFetchedSettings: boolean): void {
    this.hasFetchedSettings = hasFetchedSettings;
  }

  @Mutation
  private setAuthenticating(isAuthenticating: boolean): void {
    this.isAuthenticating = isAuthenticating;
  }

  @Mutation
  private setLoginErrorMessage(errorMessage: string | null): void {
    this.loginErrorMessage = errorMessage;
  }
}

export const AuthModule = getModule(Auth);
