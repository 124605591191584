import { isNil } from 'lodash-es';

import { FareSource } from '@/modules/api/customer-settings/customer-settings-contracts';
import { FlightLineCabin } from '@/modules/api/flight/flight-contracts';
import { FlightService } from '@/services/flight.service';

import { FlightDynamicPropertiesAllowed } from './FlightModel';

function getCabinFare(data: FlightDynamicPropertiesAllowed, cabinCode: string, fareSource: FareSource) {
  const matchedCabin = FlightService.getMatchedCabin(data, cabinCode);
  if (isNil(matchedCabin)) {
    return undefined;
  } else {
    return getFareForCabin(matchedCabin, fareSource);
  }
}

function getFareForCabin(cabin: FlightLineCabin, fareSource: FareSource) {
  switch (fareSource) {
    case FareSource.CompetitiveFares:
      return isNil(cabin.fareValue) ? undefined : cabin.fareValue.fare;
    case FareSource.PublishedFares:
      return cabin.lafPublishedFareValue;
    default:
      return undefined;
  }
}
export { getCabinFare, getFareForCabin };
