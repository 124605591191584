import { defineAsyncComponent } from 'vue';

import { CascaderItem } from '@/models/element-ui';
import { ComponentName } from '@/models/enums/components';
import { FilterFieldField, FilterFieldType } from '@/modules/api/shared-contracts';
import { isArrayValueNotEmpty } from '@/modules/flight-actions/actions/validation-options';
import { BaseFlightActionPayload, FlightAction, FlightActionType } from '@/modules/flight-actions/api/flight-actions-contracts';
import { FlightActionDefinition } from '@/modules/grid/components/dynamic-filter-fields/DynamicFilterModels';
import { ITag, TagsModule } from '@/modules/tags';
import { i18n } from '@/plugins/i18n';

const { t } = i18n.global;

export interface RemoveTagsPayload extends BaseFlightActionPayload {
  tagIds: number[];
  removeAll: boolean;
}

export interface RemoveTagsData {
  tags: ITag[];
  removeAll: boolean;
}

export class RemoveTagsAction implements FlightAction<RemoveTagsPayload> {
  public actionType: FlightActionType = FlightActionType.tagsRemove;
  public value: RemoveTagsData = {
    tags: [],
    removeAll: false,
  };
  public label = t('remove_tags');

  constructor(value?: { tags: ITag[]; removeAll: boolean }) {
    if (value) this.value = value;
  }

  get cascaderOption(): CascaderItem<FlightActionDefinition<RemoveTagsData>> {
    return {
      label: this.label,
      value: 'tagsRemove',
      meta: {
        label: this.label,
        flightActionType: FlightActionType.tagsRemove,
        uniqueField: 'tagsRemove',
        field: FilterFieldField.tagsRemove,
        type: FilterFieldType.in,
        value: this.value,
        valueKey: 'tagsRemove',
        componentName: ComponentName.TagsRemoveField,
        displayValueComponent: defineAsyncComponent(
          () => import('@/modules/flight-actions/components/action-value-renderers/RemoveTagsActionValueRenderer.vue'),
        ),
        isValueValid: (value: RemoveTagsData) => value.removeAll || isArrayValueNotEmpty(value.tags),
      },
    };
  }

  public setValueByDefinition(action: FlightActionDefinition<RemoveTagsData>): void {
    this.value = action.value;
  }

  public setPayload(payload: RemoveTagsPayload): void {
    // Only remove tags that currently exist
    const tagsToRemove = TagsModule.tags.filter((tag) => payload.tagIds.includes(tag.id));

    this.value.tags = tagsToRemove;
    this.value.removeAll = payload.removeAll;
  }

  public getPayload(): RemoveTagsPayload {
    const flightAction: RemoveTagsPayload = {
      actionType: this.actionType,
      tagIds: this.value.removeAll ? [] : this.value.tags.map((tag: ITag) => tag.id).filter((id) => id !== undefined),
      removeAll: this.value.removeAll,
    };

    if (flightAction.removeAll || flightAction.tagIds.length > 0) {
      return flightAction;
    }
  }
}
