import { RouteRecordRaw } from 'vue-router';

import { Authorities } from '@/modules/api/auth/auth-contracts';
import { RouteName } from '@/modules/shared';
import { CustomerSettingsModule } from '@/store/modules/customer-settings.module';

const ancillaryActivated = () => CustomerSettingsModule.settings.includeAncillaryInTotalRevenue ?? false;
const surchargeActivated = () => CustomerSettingsModule.settings.includeSurchargeInTotalRevenue ?? false;
const taxesActivated = () => CustomerSettingsModule.settings.includeTaxInTotalRevenue ?? false;

function codeManagementActivated(): boolean {
  return [
    CustomerSettingsModule.settings.includeAncillaryInTotalRevenue,
    CustomerSettingsModule.settings.includeSurchargeInTotalRevenue,
    CustomerSettingsModule.settings.includeTaxInTotalRevenue,
  ].some((setting) => !!setting);
}

export const REVENUE_COMPONENTS_MANAGEMENT_ROUTES: RouteRecordRaw[] = [
  {
    path: 'revenue-components-management',
    name: RouteName.revenueComponentManagement,
    component: () => import('@/modules/revenues/views/RevenueComponentsManagementView.vue'),
    redirect: { name: RouteName.ancillaryCodesManagement },
    meta: {
      authorities: [Authorities.RevenueComponentRead],
      isAllowed: codeManagementActivated,
    },
    children: [
      {
        path: 'ancillaries',
        name: RouteName.ancillaryCodesManagement,
        component: () => import('@/modules/revenues/views/AncillaryCodesManagementView.vue'),
        meta: {
          authorities: [Authorities.RevenueComponentRead],
          isAllowed: ancillaryActivated,
        },
      },
      {
        path: 'tax',
        name: RouteName.taxCodesManagement,
        component: () => import('@/modules/revenues/views/TaxCodesManagementView.vue'),
        meta: {
          authorities: [Authorities.RevenueComponentRead],
          isAllowed: taxesActivated,
        },
      },
      {
        path: 'surcharges',
        name: RouteName.surchargeCodesManagement,
        component: () => import('@/modules/revenues/views/SurchargeCodesManagementView.vue'),
        meta: {
          authorities: [Authorities.RevenueComponentRead],
          isAllowed: surchargeActivated,
        },
      },
    ],
  },
];
